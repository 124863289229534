import { defineComponent, PropType, computed } from 'vue';
import { Language } from '@/shared/model/enumerations/language.model';

export default defineComponent({
  props: {
    language: {
      type: String as PropType<keyof typeof Language>,
      required: true
    },
    width: {
      type: String,
      default: '24'
    }
  },
  setup(props) {
    const langValue = computed(() => {
      const key = props.language as keyof typeof Language;

      return Language[key];
    });

    return { langValue };
  }
});
