import { defineComponent, PropType, computed, onMounted, ref } from 'vue';

export default defineComponent({
  props: {
    value: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    errorMessage: {
      type: String,
      required: false
    }
  },
  setup() {}
});
