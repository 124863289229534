var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "w-100 d-flex flex-column flex-fill" },
    [
      _vm.mode !== "floatingLabel" && _vm.label
        ? _c("span", {
            staticClass: "text-body-2-bold text--text text--lighten-1 mb-2",
            domProps: { textContent: _vm._s(_vm.label) },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("v-select", {
        class: {
          "custom-v-selector": true,
          "custom-v-selector-error": _vm.errorMessage,
          "custom-v-selector-default": !_vm.errorMessage,
          "custom-v-selector-floating":
            !_vm.errorMessage && _vm.mode === "floatingLabel",
        },
        attrs: {
          "data-cy": "selector-multiple",
          label: _vm.mode === "floatingLabel" ? _vm.label : _vm.placeholder,
          items: _vm.items.map((item) => item.id),
          "return-object": "",
          dense: "",
          multiple: "",
          "persistent-hint": "",
          flat: "",
          solo: "",
          "menu-props": { offsetY: true },
        },
        on: {
          change: _vm.handleChange,
          focus: _vm.handleFocus,
          blur: _vm.handleBlur,
        },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function ({ index }) {
              return [
                index === 0
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "pt-1 pb-1 text-body-1-medium text--lighten1",
                        attrs: {
                          v: "",
                          "data-cy": "sselector-multiple-selection-label",
                        },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.selectionSummary) +
                            "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "item",
            fn: function ({ item }) {
              return [
                _c(
                  "div",
                  {
                    class: [
                      "text--text",
                      "text--base",
                      "select-container",
                      { "selected-item": _vm.isSelected(item) },
                      { "text-body-1-bold": _vm.isSelected(item) },
                      { "text-body-1-bold": _vm.areAllSelected },
                      { "text-body-1": !_vm.isSelected(item) },
                    ],
                    attrs: { "data-cy": "sselector-multiple-item" },
                  },
                  [
                    _c(
                      "span",
                      { attrs: { "data-cy": "sselector-multiple-item-text" } },
                      [_vm._v(_vm._s(_vm.descriptionFor(item)))]
                    ),
                    _vm._v(" "),
                    _vm.isSelected(item) || _vm.areAllSelected
                      ? _c("font-awesome-icon", {
                          staticClass: "ml-2 mr-1",
                          staticStyle: { "font-size": "18px" },
                          attrs: {
                            icon: ["fas", "check"],
                            "data-cy": "sselector-multiple-item-check-icon",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.selectedItems,
          callback: function ($$v) {
            _vm.selectedItems = $$v
          },
          expression: "selectedItems",
        },
      }),
      _vm._v(" "),
      _vm.errorMessage
        ? _c("span", {
            staticClass: "text-body-2-bold quaternary--text text--base mt-1",
            domProps: { textContent: _vm._s(_vm.errorMessage) },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.mode == "chip"
        ? _c("SSelectorChips", {
            attrs: {
              removeItem: _vm.removeItem,
              selectedItems: _vm.selectedItems,
              descriptionFor: _vm.descriptionFor,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }