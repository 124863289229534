import { defineComponent, inject, PropType, ref, watch } from 'vue';
import SButton from '@/components/stemdo-components/s-button/SButton.vue';
import SModalCentered from '@/components/stemdo-components/s-modal-centered/SModalCentered.vue';
import SModalTeamRequestConfirmation from '@/components/stemdo-components/s-modal-team-request-confirmation/SModalTeamRequestConfirmation.vue';
import TeamRequestService from '@/sections/admin/entities/team-request/team-request.service';
import { TeamRequest } from '@/shared/model/team-request.model';
import { useRouter } from '@/plugins/router';

export default defineComponent({
  props: {
    teamRequest: {
      type: Object as () => TeamRequest,
      required: true
    },
    onClose: {
      type: Function
    },
    isOpen: {
      type: Boolean
    }
  },
  components: {
    SButton,
    SModalCentered,
    SModalTeamRequestConfirmation
  },
  setup(props) {
    const router = useRouter();
    const customModalProps = { bodyPaddingY: 0, fullWidthFooter: true };
    const teamRequestService: TeamRequestService = inject('teamRequestService');

    const saveTeamRequest = (teamRequest: TeamRequest) => {
      teamRequestService
        .partialUpdate(teamRequest)
        .then(_ => {
          router.push({ name: 'teamRequestsClient', query: { 'saved-success': 'true' } });
        })
        .catch(error => {
          console.error(error);
        });
    };

    const requestTeamRequest = (teamRequest: TeamRequest) => {
      teamRequestService
        .blockAndPartialUpdate(teamRequest)
        .then(_ => {
          // props.onOpenModal();
        })
        .catch(error => {
          console.error(error);
        });
    };

    const handleClose = () => {
      props.onClose();
    };

    return {
      customModalProps,
      saveTeamRequest,
      requestTeamRequest,
      handleClose
    };
  }
});
