var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "p-0", attrs: { "data-cy": "more-filters-container" } },
    [
      _c(
        "v-row",
        {
          staticClass: "mb-2",
          attrs: { "no-gutters": "", "data-cy": "tech-label-row" },
        },
        [
          _c("v-col", [
            _c(
              "span",
              {
                staticClass: "text-body-2-bold text--text text--lighten-1",
                attrs: { "data-cy": "tech-label" },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t("explore.more-filters.modal.filter.label-tech")
                    ) +
                    "\n      "
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mb-6",
          attrs: { "no-gutters": "", "data-cy": "tech-filter-row" },
        },
        [
          _c(
            "v-col",
            [
              _c("SAutocomplete", {
                attrs: {
                  "data-cy": "tech-filter-autocomplete",
                  placeholder:
                    "explore.more-filters.modal.filter.placeholder-tech",
                  items: _vm.techItems,
                  filterField: _vm.technologiesField,
                  action: _vm.changeTechFilter,
                  inputHeight: 45,
                  labelDisplaySelection: true,
                  tempFilters: true,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mb-2",
          attrs: { "no-gutters": "", "data-cy": "competency-label-row" },
        },
        [
          _c("v-col", [
            _c(
              "span",
              {
                staticClass: "text-body-2-bold text--text text--lighten-1",
                attrs: { "data-cy": "competency-label" },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t(
                        "explore.more-filters.modal.filter.label-competency"
                      )
                    ) +
                    "\n      "
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mb-6",
          attrs: { "no-gutters": "", "data-cy": "competency-filter-row" },
        },
        [
          _c(
            "v-col",
            [
              _c("SAutocompleteMultipleRating", {
                attrs: {
                  "data-cy": "competency-filter-autocomplete",
                  placeholder:
                    "explore.more-filters.modal.filter.placeholder-competency",
                  items: _vm.compItems,
                  filterField: _vm.competenciesField,
                  action: _vm.changeCompFilter,
                  inputHeight: 45,
                  labelDisplaySelection: true,
                  label: "explore.filter.label-competency",
                  tempFilters: true,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mb-2",
          attrs: { "no-gutters": "", "data-cy": "soft-skills-label-row" },
        },
        [
          _c("v-col", [
            _c(
              "span",
              {
                staticClass: "text-body-2-bold text--text text--lighten-1",
                attrs: { "data-cy": "soft-skills-label" },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t(
                        "explore.more-filters.modal.filter.label-aptitudes"
                      )
                    ) +
                    "\n      "
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mb-6",
          attrs: { "no-gutters": "", "data-cy": "soft-skills-filter-row" },
        },
        [
          _c(
            "v-col",
            [
              _c("SAutocompleteMultiple", {
                attrs: {
                  "data-cy": "soft-skills-filter-autocomplete",
                  placeholder:
                    "explore.more-filters.modal.filter.placeholder-aptitudes",
                  items: _vm.softSkillsItems,
                  filterField: _vm.softSkillField,
                  action: _vm.changeSoftSkillFilter,
                  inputHeight: 45,
                  labelDisplaySelection: true,
                  label: "explore.more-filters.modal.filter.label-aptitudes",
                  tempFilters: true,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mb-2",
          attrs: { "no-gutters": "", "data-cy": "cert-label-row" },
        },
        [
          _c("v-col", [
            _c(
              "span",
              {
                staticClass: "text-body-2-bold text--text text--lighten-1",
                attrs: { "data-cy": "cert-label" },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t("explore.more-filters.modal.filter.label-cert")
                    ) +
                    "\n      "
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mb-6",
          attrs: { "no-gutters": "", "data-cy": "cert-filter-row" },
        },
        [
          _c(
            "v-col",
            [
              _c("SAutocompleteMultiple", {
                attrs: {
                  "data-cy": "cert-filter-autocomplete",
                  placeholder:
                    "explore.more-filters.modal.filter.placeholder-cert",
                  items: _vm.certItems,
                  filterField: _vm.certField,
                  action: _vm.changeCertFilter,
                  inputHeight: 45,
                  labelDisplaySelection: true,
                  label: "explore.more-filters.modal.filter.label-cert",
                  tempFilters: true,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mb-2",
          attrs: { "no-gutters": "", "data-cy": "experience-level-label-row" },
        },
        [
          _c("v-col", [
            _c(
              "span",
              {
                staticClass: "text-body-2-bold text--text text--lighten-1",
                attrs: { "data-cy": "experience-level-label" },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t(
                        "explore.more-filters.modal.filter.label-experience-level"
                      )
                    ) +
                    "\n      "
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mb-6",
          attrs: { "no-gutters": "", "data-cy": "experience-level-filter-row" },
        },
        [
          _c(
            "v-col",
            [
              _c("ExperienceLevelOptions", {
                attrs: {
                  "data-cy": "experience-level-options",
                  filterField: _vm.experienceLevelField,
                  action: _vm.changeExpFilter,
                  tempFilters: true,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mb-2",
          attrs: { "no-gutters": "", "data-cy": "rate-label-row" },
        },
        [
          _c("v-col", [
            _c(
              "span",
              {
                staticClass: "text-body-2-bold text--text text--lighten-1",
                attrs: { "data-cy": "rate-label" },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t("explore.more-filters.modal.filter.label-rate")
                    ) +
                    "\n      "
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mb-6",
          attrs: { "no-gutters": "", "data-cy": "rate-filter-row" },
        },
        [
          _c(
            "v-col",
            [
              _c("SRangeSliderStepsRate", {
                attrs: {
                  "data-cy": "rate-filter-slider",
                  filterField: _vm.rateField,
                  rates: _vm.rates,
                  action: _vm.changeRateFilter,
                  tempFilters: true,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mb-2",
          attrs: { "no-gutters": "", "data-cy": "office-label-row" },
        },
        [
          _c("v-col", [
            _c(
              "span",
              {
                staticClass: "text-body-2-bold text--text text--lighten-1",
                attrs: { "data-cy": "office-label" },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t("explore.more-filters.modal.filter.label-office")
                    ) +
                    "\n      "
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mb-6",
          attrs: { "no-gutters": "", "data-cy": "office-filter-row" },
        },
        [
          _c(
            "v-col",
            [
              _c("SAutocomplete", {
                attrs: {
                  "data-cy": "office-autocomplete",
                  placeholder:
                    "explore.more-filters.modal.filter.placeholder-office",
                  items: _vm.officeItems,
                  filterField: _vm.officeField,
                  action: _vm.changeOfficeFilter,
                  inputHeight: 45,
                  labelDisplaySelection: true,
                  tempFilters: true,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mb-2",
          attrs: { "no-gutters": "", "data-cy": "language-label-row" },
        },
        [
          _c("v-col", [
            _c(
              "span",
              {
                staticClass: "text-body-2-bold text--text text--lighten-1",
                attrs: { "data-cy": "language-label" },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t("explore.more-filters.modal.filter.label-lang")
                    ) +
                    "\n      "
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { "no-gutters": "", "data-cy": "language-filter-row" } },
        [
          _c(
            "v-col",
            [
              _c("SwitchEnglish", {
                attrs: {
                  "data-cy": "language-filter-switch",
                  filterField: _vm.langField,
                  action: _vm.changeLangFilter,
                  tempFilters: true,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }