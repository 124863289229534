var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "w-100 d-flex flex-column flex-fill" }, [
    _vm.label
      ? _c("span", {
          staticClass: "text-body-2-bold text--text text--lighten-1 mb-2",
          domProps: { textContent: _vm._s(_vm.label) },
        })
      : _vm._e(),
    _vm._v(" "),
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.inputVal,
          expression: "inputVal",
        },
      ],
      class: {
        "w-100": true,
        "error-style": _vm.errorMessage,
        "default-style": !_vm.errorMessage,
      },
      attrs: {
        type: "text",
        placeholder: _vm.placeholder,
        rows: "5",
        maxlength: _vm.maxInputLength,
      },
      domProps: { value: _vm.inputVal },
      on: {
        input: [
          function ($event) {
            if ($event.target.composing) return
            _vm.inputVal = $event.target.value
          },
          function ($event) {
            return _vm.$emit("input", $event.target.value)
          },
        ],
      },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "w-100 d-flex mt-1" }, [
      _vm.errorMessage
        ? _c("span", {
            staticClass: "text-body-2-bold quaternary--text text--base",
            domProps: { textContent: _vm._s(_vm.errorMessage) },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("span", {
        class: [
          "ml-auto text-body-3 text--text",
          _vm.inputValCount > 0 ? "text--lighten-1" : "text--lighten-2",
        ],
        domProps: {
          textContent: _vm._s(
            `${_vm.inputValCount}/${_vm.maxInputLength} ${_vm.$t(
              "sInputTextarea.character"
            )}`
          ),
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }