var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "pa-0 main-box",
      attrs: { "data-cy": "stemdoer-skills-section" },
    },
    [
      _c(
        "v-row",
        {
          staticClass: "pa-4",
          attrs: { "data-cy": "stemdoer-competence-section", "no-gutters": "" },
        },
        [
          _c("v-col", { staticClass: "mb-2", attrs: { cols: "12" } }, [
            _c(
              "span",
              {
                staticClass: "text-body-2-medium text--text text--base",
                attrs: { "data-cy": "stemdoer-skills-title" },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("sBoxCompsAndCerts.comp")) +
                    "\n      "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm.stemdoer.technologyScores
            ? _c(
                "v-col",
                { attrs: { cols: "12", "data-cy": "stemdoer-skills-list" } },
                _vm._l(
                  _vm.stemdoer.technologyScores.slice(0, 10),
                  function (technologyScore) {
                    return _c(
                      "v-tooltip",
                      {
                        key: technologyScore.idTechnology,
                        attrs: {
                          top: "",
                          "max-width": "50em",
                          "data-cy": "stemdoer-skill-tooltip",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-chip",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "mr-1 my-1 chips",
                                          attrs: {
                                            density: "compact",
                                            "data-cy": "stemdoer-skill-chip",
                                          },
                                        },
                                        "v-chip",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c("span", {
                                        staticClass: "mr-1",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$t(
                                              `technologyMaster.list.${technologyScore.idTechnology}`
                                            )
                                          ),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.getStarSkills(
                                              technologyScore.score
                                            )
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            attrs: {
                              "data-cy": "stemdoer-skill-tooltip-content",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex align-center pt-1" },
                              [
                                _c("strong", { staticClass: "pr-1" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getTitleBadge(technologyScore.score)
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.getStarsTooltip(technologyScore.score),
                                  function (tier) {
                                    return _c(
                                      "span",
                                      { key: tier },
                                      [
                                        _c("v-img", {
                                          attrs: {
                                            width: 20,
                                            src: "/content/svgs/star/star-fill-white.svg",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("p", { attrs: { color: "#ECECEF" } }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.getInfoBadge(
                                      technologyScore.score,
                                      technologyScore.idTechnology
                                    )
                                  ) +
                                  "\n          "
                              ),
                            ]),
                          ]
                        ),
                      ]
                    )
                  }
                ),
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.stemdoer.certifications && _vm.stemdoer.certifications.length != 0
        ? _c("hr", {
            staticClass: "my-0 mx-3",
            attrs: { "data-cy": "stemdoer-certifications-separator" },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.stemdoer.certifications && _vm.stemdoer.certifications.length != 0
        ? _c(
            "v-row",
            {
              staticClass: "pa-4",
              attrs: {
                "data-cy": "stemdoer-certifications-section",
                "no-gutters": "",
              },
            },
            [
              _c("v-col", { staticClass: "mb-2", attrs: { cols: "12" } }, [
                _c(
                  "span",
                  {
                    staticClass: "text-body-2-medium text--text text--base",
                    attrs: { "data-cy": "stemdoer-certifications-title" },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("sBoxCompsAndCerts.cert")) +
                        "\n      "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                _vm._l(_vm.stemdoer.certifications, function (badge, index) {
                  return _c(
                    "v-chip",
                    {
                      key: index,
                      staticClass: "mr-1 chips",
                      staticStyle: { "margin-top": "0.2em" },
                      attrs: {
                        density: "compact",
                        "data-cy": "stemdoer-certification-chip",
                      },
                    },
                    [_vm._v("\n        " + _vm._s(badge.name) + "\n      ")]
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }