var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { "data-cy": "stemdoer-education-section" } }, [
    _c(
      "p",
      {
        staticClass: "header-content",
        attrs: { "data-cy": "stemdoer-education-title" },
      },
      [
        _vm._v(
          "\n    " + _vm._s(_vm.$t("sBoxStemdoerEducation.education")) + "\n  "
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "main-box" },
      [
        _c(
          "v-col",
          {
            staticClass: "pl-3 pt-3 d-flex",
            attrs: { cols: "12", "data-cy": "stemdoer-education-content" },
          },
          [
            _c(
              "v-sheet",
              {
                staticClass: "mr-2",
                staticStyle: {
                  "border-radius": "3px",
                  "font-size": "16px",
                  "padding-left": "3px",
                  "padding-top": "2px",
                },
                attrs: {
                  color: "#f6f6f6",
                  elevation: "0",
                  width: "35px",
                  height: "35px",
                  "data-cy": "stemdoer-education-icon",
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: "/content/svgs/stemdoer-detail/education.svg",
                    width: "30px",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "pa-0 grade",
                attrs: { "data-cy": "stemdoer-education-text" },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.stemdoer.education?.replace(";", " ")) +
                    "\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }