import { Authority } from '@/shared/security/authority';
import ExplorePage from '@/pages/client/explore/ExplorePage.vue';
import CheckoutPage from '@/pages/client/checkout/CheckoutPage.vue';
import TeamRequestsPage from '@/pages/client/team-requests/TeamRequestsPage.vue';
import CandidatesPage from '@/pages/client/candidates/CandidatesPage.vue';
import DashboardPage from '@/pages/client/dashboard/DashboardPage.vue';
import TeamsPage from '@/pages/client/teams/TeamsPage.vue';
import CalendarPage from '@/pages/client/calendar/CalendarPage.vue';

export default [
  {
    path: '/dashboard',
    name: 'dashboardClient',
    component: DashboardPage,
    meta: { authorities: [Authority.CLIENT] }
  },
  {
    path: '/explore',
    name: 'exploreClient',
    component: ExplorePage,
    meta: { authorities: [Authority.CLIENT] }
  },
  {
    path: '/checkout',
    name: 'checkoutClient',
    component: CheckoutPage,
    meta: { authorities: [Authority.CLIENT] }
  },
  {
    path: '/team-requests',
    name: 'teamRequestsClient',
    component: TeamRequestsPage,
    meta: { authorities: [Authority.CLIENT] }
  },
  {
    path: '/teams/candidates',
    name: 'teamsCandidatesClient',
    component: CandidatesPage,
    meta: { authorities: [Authority.CLIENT] }
  },
  {
    path: '/teams/teams',
    name: 'teamsTeamsClient',
    component: TeamsPage,
    meta: { authorities: [Authority.CLIENT] }
  },
  {
    path: '/teams/calendar',
    name: 'teamsCalendarClient',
    component: CalendarPage,
    meta: { authorities: [Authority.CLIENT] }
  }
];
