import { defineComponent, ref, inject, onMounted, watch } from 'vue';
import SInputText from '@/components/stemdo-components/s-input-text/SInputText.vue';
import SInputDate from '@/components/stemdo-components/s-input-date/SInputDate.vue';
import SSelectorMultiple from '@/components/stemdo-components/s-selector-multiple/SSelectorMultiple.vue';
import TechnologyService from '@/sections/admin/entities/technology/technology.service';
import SInputTextarea from '@/components/stemdo-components/s-input-textarea/SInputTextarea.vue';
import { IExperience } from '@/shared/model/experience.model';
import { useI18N } from '@/plugins/i18n';
import useVuelidate from '@vuelidate/core';
import { experienceForm, experienceFormRules, fillExperienceForm, resetExperienceForm } from './experienceForm';

export default defineComponent({
  props: {
    experience: {
      type: Object as () => IExperience,
      default: undefined
    },
    isOpen: {
      type: Boolean,
      required: true
    },
    onExperienceSave: {
      type: Function,
      required: true
    },
    onCloseModal: {
      type: Function,
      required: true
    }
  },
  components: {
    SInputText,
    SInputDate,
    SSelectorMultiple,
    SInputTextarea
  },
  setup(props) {
    const i18n = useI18N();
    const technologyService: TechnologyService = inject('technologyService');
    const competenciesItems = ref([]);
    const vuelidate = useVuelidate(experienceFormRules, experienceForm);

    onMounted(async () => {
      competenciesItems.value = (await technologyService.retrieve()).map(item => ({
        id: item.id,
        desc: item.name
      }));
    });

    watch(
      () => props.experience,
      value => {
        if (value) {
          fillExperienceForm(value, competenciesItems.value);
        }
      }
    );

    const onSaveClick = async () => {
      const technologiesSeparatedByComma = experienceForm.competencies
        .map(competencyId => {
          const translation = i18n.t(`technologyMaster.list.${competencyId}`, competencyId) as string;
          return typeof translation === 'string' && translation.includes('technologyMaster.list.') ? competencyId : translation;
        })
        .join(';');

      const experience: IExperience = {
        id: props.experience?.id,
        title: experienceForm.position,
        company: experienceForm.company,
        initDate: experienceForm.startDate !== '' ? new Date(experienceForm.startDate) : null,
        endDate: experienceForm.endDate !== '' ? new Date(experienceForm.endDate) : null,
        technologies: technologiesSeparatedByComma,
        description: experienceForm.description,
        validation: true
      };
      const result = await vuelidate.value.$validate();
      if (result === true) {
        resetExperienceForm();
        vuelidate.value.$reset();
        props.onExperienceSave(experience);
      }
    };

    const closeModal = () => {
      resetExperienceForm();
      vuelidate.value.$reset();
      props.onCloseModal();
    };

    return {
      vuelidate,
      experienceForm,
      competenciesItems,
      onSaveClick,
      closeModal
    };
  }
});
