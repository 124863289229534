var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      (_vm.hasAnyAuthority("ROLE_RRHH") || _vm.hasAnyAuthority("ROLE_ADMIN")) &&
      _vm.authenticated
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/vertical", "data-cy": "menu-vertical" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
              _vm._v(" "),
              _c("span", {
                domProps: {
                  textContent: _vm._s(_vm.$t("global.menu.entities.vertical")),
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority("ROLE_ADMIN") && _vm.authenticated
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/staff", "data-cy": "menu-staff" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
              _vm._v(" "),
              _c("span", {
                domProps: {
                  textContent: _vm._s(_vm.$t("global.menu.entities.staff")),
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/stemdoer", "data-cy": "menu-stemdoer" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("global.menu.entities.stemdoer")),
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/technology", "data-cy": "menu-technology" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("global.menu.entities.technology")),
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/certification", "data-cy": "menu-certification" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("global.menu.entities.certification")),
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/cohort", "data-cy": "menu-cohort" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("global.menu.entities.cohort")),
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasAnyAuthority("ROLE_ADMIN") && _vm.authenticated
        ? _c(
            "b-dropdown-item",
            {
              attrs: {
                to: "/technology-term",
                "data-cy": "menu-technology-term",
              },
            },
            [
              _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
              _vm._v(" "),
              _c("span", {
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("global.menu.entities.technologyTerm")
                  ),
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority("ROLE_ADMIN") && _vm.authenticated
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/client", "data-cy": "menu-client" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
              _vm._v(" "),
              _c("span", {
                domProps: {
                  textContent: _vm._s(_vm.$t("global.menu.entities.client")),
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: "/client-department",
            "data-cy": "menu-client-department",
          },
        },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("global.menu.entities.clientDepartment")
              ),
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/company", "data-cy": "menu-company" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("global.menu.entities.company")),
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasAnyAuthority("ROLE_ADMIN") && _vm.authenticated
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/team-request", "data-cy": "menu-team-request" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
              _vm._v(" "),
              _c("span", {
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("global.menu.entities.teamRequest")
                  ),
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/notification", "data-cy": "menu-notification" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("global.menu.entities.notification")),
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasAnyAuthority("ROLE_ADMIN") && _vm.authenticated
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/activity-log", "data-cy": "menu-activity-log" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
              _vm._v(" "),
              _c("span", {
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("global.menu.entities.activityLog")
                  ),
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/client-rate", "data-cy": "menu-client-rate" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("global.menu.entities.clientRate")),
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/cohort-request", "data-cy": "menu-cohort-request" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("global.menu.entities.cohortRequest")),
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: "/team-request-proposal",
            "data-cy": "menu-team-request-proposal",
          },
        },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("global.menu.entities.teamRequestProposal")
              ),
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/soft-skill", "data-cy": "menu-soft-skill" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("global.menu.entities.softSkill")),
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }