/* eslint-disable prettier/prettier */
import { PropType, defineComponent, ref, inject, onMounted, watch } from 'vue';
import { useStore } from '@/plugins/vuex';
import { FilterFields } from '../../model/Filters';
import SAutocompleteMultipleRating from '../filter/s-autocomplete-multiple-rating/SAutocompleteMultipleRating.vue';
import { IAtocompleteMultipleRatingState } from '../filter/s-autocomplete-multiple-rating/sAutocompleteMultipleRating';
import SAutocompleteMultiple from '../filter/s-autocomplete-multiple/SAutocompleteMultiple.vue';
import { IAtocompleteMultipleState } from '../filter/s-autocomplete-multiple/sAutocompleteMultiple';
import SAutocomplete from '../filter/s-autocomplete/SAutocomplete.vue';
import { IAtocompleteState } from '../filter/s-autocomplete/sAutocomplete';
import CertificationService from '@/sections/admin/entities/certification/certification.service';
import { Certification } from '@/shared/model/certification.model';
import { IRate } from '@/shared/model/rate.model';
import SRangeSliderStepsRate from '../filter/s-range-slider-steps-rate/SRangeSliderStepsRate.vue';
import { IRangeSliderStepsRateState } from '../filter/s-range-slider-steps-rate/sRangeSliderStepsRate';
import SwitchEnglish from '../filter/switch-english/SwitchEnglish.vue';
import ExperienceLevelOptions from '../filter/experience-level/ExperienceLevelOptions.vue';
import StemdoerService from '@/sections/admin/entities/stemdoer/stemdoer.service';

export default defineComponent({
  components: {
    SAutocomplete,
    SAutocompleteMultiple,
    SAutocompleteMultipleRating,
    SRangeSliderStepsRate,
    SwitchEnglish,
    ExperienceLevelOptions
  },
  props: {
    techItems: {
      type: Array as PropType<IAtocompleteMultipleState[]>,
      required: true
    },
    compItems: {
      type: Array as PropType<IAtocompleteMultipleRatingState[]>,
      required: true
    },
    softSkillsItems: {
      type: Array as PropType<IAtocompleteMultipleState[]>,
      required: true
    },
    rates: {
      type: Array as () => IRate[],
      required: true
    },
    clickTechFilter: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const certificationService: CertificationService = inject('certificationService');
    const stemdoerService: StemdoerService = inject('stemdoerService');
    const technologiesField = FilterFields.Technology;
    const competenciesField = FilterFields.Competency;
    const softSkillField = FilterFields.SoftSkill;
    const certField = FilterFields.Certification;
    const experienceLevelField = FilterFields.ExperienceLevel;
    const rateField = FilterFields.Rate;
    const langField = FilterFields.Language;
    const certItems = ref<IAtocompleteMultipleState[]>([]);
    const officeField = FilterFields.Office;
    const officeItems = ref<IAtocompleteState[]>([]);

    onMounted(() => {
      loadCertifications();
      loadOffices();
    });

    const loadCertifications = () => {
      certificationService.retrieve().then(res => {
        const certifications: Certification[] = Certification.toCertifications(res.data);
        certifications.forEach(certification => {
          certItems.value.push({ id: certification.id, desc: certification.name });
        });
      });
    };

    const loadOffices = () => {
      stemdoerService.getOffices().then(res => {
        res.forEach(office => {
          officeItems.value.push({ id: office, desc: office });
        });
      });
    };

    const changeOfficeFilter = (field: FilterFields, newSelectedItem: IAtocompleteState) => {
      const payload = { field, newSelectedItem };
      store.commit('filterStore/updateAutoCompleteTempState', payload);
    };

    const changeTechFilter = (field: FilterFields, newSelectedItem: IAtocompleteState) => {
      const payload = { field, newSelectedItem };
      store.commit('filterStore/updateAutoCompleteTempState', payload);
      props.clickTechFilter(field, newSelectedItem, true);
    };

    const changeCompFilter = (field: FilterFields, newSelectedItem: IAtocompleteMultipleRatingState) => {
      const payload = { field, newSelectedItem };
      store.commit('filterStore/updateAutoCompleteMultipleRatingTempState', payload);
    };

    const changeSoftSkillFilter = (field: FilterFields, newSelectedItems: IAtocompleteMultipleState[]) => {
      const payload = { field, newSelectedItems };
      store.commit('filterStore/updateAutoCompleteMultipleTempState', payload);
    };

    const changeCertFilter = (field: FilterFields, newSelectedItems: IAtocompleteMultipleState[]) => {
      const payload = { field, newSelectedItems };
      store.commit('filterStore/updateAutoCompleteMultipleTempState', payload);
    };

    const changeRateFilter = (field: FilterFields, newRateRange: IRangeSliderStepsRateState) => {
      const payload = { field, newRateRange };
      store.commit('filterStore/updateRangeSliderStepsRateTempState', payload);
    };

    const changeLangFilter = (field: FilterFields, newVal: boolean) => {
      const payload = { field, newVal };
      store.commit('filterStore/updateCheckboxTempState', payload);
    };

    const changeExpFilter = (field: FilterFields, newVal: number) => {
      const payload = { field, newVal };
      store.commit('filterStore/updateCheckboxListTempState', payload);
    };

    return {
      certItems,
      technologiesField,
      competenciesField,
      officeField,
      softSkillField,
      officeItems,
      certField,
      experienceLevelField,
      rateField,
      langField,
      changeTechFilter,
      changeOfficeFilter,
      changeCompFilter,
      changeSoftSkillFilter,
      changeCertFilter,
      changeRateFilter,
      changeLangFilter,
      changeExpFilter
    };
  }
});
