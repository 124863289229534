import { defineComponent, PropType, computed, ref, watch, onMounted, inject } from 'vue';
import SSelector from '@/components/stemdo-components/s-selector/SSelector.vue';
import SSearch from '@/components/stemdo-components/s-search/SSearch.vue';
import { useI18N } from '@/plugins/i18n';
import SSelectorMultiple from '@/components/stemdo-components/s-selector-multiple/SSelectorMultiple.vue';
import { useStore } from '@/plugins/vuex';
import { Stemdoers } from '@/shared/model/stemdoer.model';
import VerticalService from '@/sections/admin/entities/vertical/vertical.service';

const stemdoerStatus = {
  ACTIVE: 'active',
  INACTIVE: 'inactive'
};

export default defineComponent({
  props: {
    stemdoers: {
      type: Array as PropType<Stemdoers[]>,
      required: true
    },
    onSearch: {
      type: Function,
      required: true
    },
    onVerticalChange: {
      type: Function,
      required: true
    },
    onStatusChange: {
      type: Function,
      required: true
    }
  },
  components: {
    SSelector,
    SSelectorMultiple,
    SSearch
  },
  setup(props) {
    const verticalService: VerticalService = inject('verticalService');
    const store = useStore();
    const i18n = useI18N();
    const verticalsSelected = ref<string[]>([]);
    const statusSelected = ref<string[]>([]);
    const storedSearch = ref<string>(undefined);
    const verticalItems = ref([]);
    const verticalsData = ref([]);

    onMounted(() => {
      fetchVerticals();
      const storedFilters = store.getters['pagesStore/manager/stemdoers/filters'];
      if (storedFilters.vertical) verticalsSelected.value = storedFilters.vertical;
      if (storedFilters.status) statusSelected.value = storedFilters.status;
      storedSearch.value = storedFilters.search;
    });

    watch(
      () => verticalsSelected.value,
      verticals => {
        props.onVerticalChange(verticals);
      }
    );

    watch(
      () => statusSelected.value,
      status => {
        props.onStatusChange(status);
      }
    );

    const fetchVerticals = async () => {
      try {
        const verticals = await verticalService.retrieve();
        verticalsData.value = verticals;
        updateVerticalItems();
      } catch (error) {
        console.error('Error fetching verticals:', error);
      }
    };

    const updateVerticalItems = () => {
      const verticalsSet = new Set([
        {
          id: 'VIEW_ALL',
          desc: i18n.t('stemdoers-filter-list.vertical.all') as string
        }
      ]);
      verticalsData.value.forEach(vertical => {
        verticalsSet.add({
          id: vertical.id,
          desc: i18n.t(`stemdoers-filter-list.vertical.${vertical.name.toLowerCase().replace(/\s+/g, '')}`) as string
        });
      });
      verticalItems.value = Array.from(verticalsSet);
    };

    watch(() => i18n.locale, updateVerticalItems);

    const statusItems = computed(() => {
      const statusSet = new Set([
        {
          id: 'VIEW_ALL',
          desc: i18n.t('stemdoers-filter-list.status.all') as string
        }
      ]);

      Object.values(stemdoerStatus).forEach(status => {
        let id;
        let translation = '';
        switch (status) {
          case stemdoerStatus.ACTIVE:
            id = false;
            translation = i18n.t('stemdoers-filter-list.status.active') as string;
            break;
          case stemdoerStatus.INACTIVE:
            id = true;
            translation = i18n.t('stemdoers-filter-list.status.inactive') as string;
            break;
        }
        statusSet.add({
          id: id,
          desc: translation
        });
      });
      return Array.from(statusSet);
    });

    return {
      verticalsSelected,
      statusSelected,
      storedSearch,
      verticalItems,
      statusItems
    };
  }
});
