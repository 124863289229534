var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { "data-cy": "container-checkoutGroup" } },
    [
      _c(
        "v-row",
        { attrs: { "data-cy": "row-checkoutGroup", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "pa-0", attrs: { cols: "12" } },
            [
              _c("SButton", {
                attrs: {
                  "data-cy": "btn-primary-request-block",
                  sText: _vm.$t("checkout.buttonGroup.client.saved.primaryBtn"),
                  btnStyle: "primary",
                  action: () => _vm.openTeamRequestBlockModal(),
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pa-0 mt-4", attrs: { cols: "12" } },
            [
              _c("SButton", {
                attrs: {
                  "data-cy": "btn-secondary-request-save",
                  sText: _vm.$t(
                    "checkout.buttonGroup.client.saved.secondaryBtn"
                  ),
                  btnStyle: "secondary",
                  action: _vm.openTeamRequestSaveModal,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("SModalTeamRequestBlock", {
        attrs: {
          "data-cy": "modal-team-request-block",
          teamRequest: _vm.teamRequest,
          isOpen: _vm.isTeamRequestModalOpen,
          onClose: _vm.closeTeamRequestConfirmationModal,
          action: _vm.onClickTeamRequestConfirmationModal,
        },
      }),
      _vm._v(" "),
      _c("SModalTeamRequestSave", {
        attrs: {
          "data-cy": "modal-team-request-save",
          teamRequest: _vm.teamRequest,
          isOpen: _vm.isTeamRequestSaveModalOpen,
          onClose: _vm.closeTeamRequestSaveModal,
          action: _vm.onClickTeamRequestConfirmationModal,
          customProps: _vm.customPropsModal,
        },
      }),
      _vm._v(" "),
      _c("SModalCentered", {
        attrs: {
          "data-cy": "modal-team-request-confirmation",
          isOpen: _vm.isTeamRequestConfirmationModalOpen,
          title: _vm.i18n.t("checkout.modal.confirmation.title"),
          closeModal: () => {
            _vm.isTeamRequestConfirmationModalOpen = false
            _vm.gotoExplore()
          },
          customProps: _vm.customModalProps,
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "v-row",
                  { attrs: { "data-cy": "row-confirmation-modal-body" } },
                  [
                    _c("v-col", { attrs: { cols: "12" } }, [
                      _vm.requestType == "request"
                        ? _c("p", {
                            staticClass: "text-body-1",
                            attrs: {
                              "data-cy": "p-confirmation-modal-body-request",
                            },
                            domProps: {
                              textContent: _vm._s(
                                _vm.i18n.t("checkout.modal.confirmation.body")
                              ),
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.requestType == "save"
                        ? _c("p", {
                            staticClass: "text-body-1",
                            attrs: {
                              "data-cy": "p-confirmation-modal-body-save",
                            },
                            domProps: {
                              textContent: _vm._s(
                                _vm.i18n.t(
                                  "checkout.modal.confirmation.body-save"
                                )
                              ),
                            },
                          })
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "v-row",
                  { attrs: { "data-cy": "row-confirmation-modal-footer" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("SButton", {
                          attrs: {
                            "data-cy": "btn-confirmation-modal-explore",
                            sText: _vm.i18n.t(
                              "checkout.modal.confirmation.button"
                            ),
                            btnStyle: "primary",
                            action: _vm.gotoExplore,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }