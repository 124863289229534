import { computed, defineComponent, PropType, ref, watch } from 'vue';
import CheckoutGroup from './groups/manager/checkout-group/CheckoutGroup.vue';
import NewProposalGroup from './groups/manager/new-proposal-group/NewProposalGroup.vue';
import ValidatedGroup from './groups/client/validated-group/ValidatedGroup.vue';
import SavedGroup from './groups/client/saved-group/SavedGroup.vue';
import CheckoutClientGroup from './groups/client/checkout-group/CheckoutGroup.vue';
import CheckoutConfirmationModal from './modal/manager/checkout-confirmation-modal/CheckoutConfirmationModal.vue';
import CheckoutConfirmationClientModal from './modal/client/checkout-modal/CheckoutClientModal.vue';
import { TeamRequest } from '@/shared/model/team-request.model';
import { ExtendedTeamRequestStatus } from '../enum/extendedTeamRequestStatus';
import ConfirmationModal from './modal/confirmation-modal/ConfirmationModal.vue';
import NewProposalConfirmationModal from './modal/manager/new-proposal-confirmation-modal/NewProposalConfirmationModal.vue';

export enum ButtonGroupModals {
  MANAGER_CHECKOUT_CONFIRMATION = 'MANAGER_CHECKOUT_CONFIRMATION',
  MANAGER_NEW_PROPOSAL_CONFIRMATION = 'MANAGER_NEW_PROPOSAL_CONFIRMATION',
  CONFIRMATION = 'CONFIRMATION',
  CHECK_CART_SAVE_TR = 'CHECK_CART_SAVE_TR'
}

export default defineComponent({
  props: {
    teamRequest: {
      type: Object as () => TeamRequest,
      required: true
    },
    teamRequestStatus: {
      type: String as PropType<ExtendedTeamRequestStatus>
    }
  },
  components: {
    CheckoutGroup,
    CheckoutClientGroup,
    NewProposalGroup,
    ConfirmationModal,
    CheckoutConfirmationModal,
    CheckoutConfirmationClientModal,
    NewProposalConfirmationModal,
    ValidatedGroup,
    SavedGroup
  },
  setup(props) {
    const mutableTeamRequest = ref<TeamRequest>({ ...props.teamRequest });
    const isModalOpen = ref(false);

    watch(
      () => props.teamRequest,
      newVal => {
        mutableTeamRequest.value = { ...newVal };
      },
      { deep: true }
    );

    const closeModal = () => {
      isModalOpen.value = false;
    };

    const openModal = (data?: any) => {
      if (data) mutableTeamRequest.value.id = data.id;
      isModalOpen.value = true;
    };

    const currentModalComponent = computed(() => {
      switch (props.teamRequestStatus) {
        case ExtendedTeamRequestStatus.MANAGER_AND_CHECKOUT:
          return 'CheckoutConfirmationModal';
        case ExtendedTeamRequestStatus.MANAGER_AND_NEW_PROPOSAL:
          return 'NewProposalConfirmationModal';
        case ExtendedTeamRequestStatus.CLIENT_AND_VALIDATED:
        case ExtendedTeamRequestStatus.CLIENT_AND_SAVED:
          return 'ConfirmationModal';
        case ExtendedTeamRequestStatus.CLIENT_AND_CHECKOUT:
          return 'CheckoutConfirmationClientModal';
        default:
          return null;
      }
    });

    const currentComponent = computed(() => {
      switch (props.teamRequestStatus) {
        case ExtendedTeamRequestStatus.MANAGER_AND_CHECKOUT:
          return 'CheckoutGroup';
        case ExtendedTeamRequestStatus.MANAGER_AND_NEW_PROPOSAL:
          return 'NewProposalGroup';
        case ExtendedTeamRequestStatus.CLIENT_AND_VALIDATED:
          return 'ValidatedGroup';
        case ExtendedTeamRequestStatus.CLIENT_AND_SAVED:
          return 'SavedGroup';
        case ExtendedTeamRequestStatus.CLIENT_AND_CHECKOUT:
          return 'CheckoutClientGroup';
        default:
          return null;
      }
    });

    return {
      mutableTeamRequest,
      isModalOpen,
      currentComponent,
      currentModalComponent,
      openModal,
      closeModal
    };
  }
});
