<template>
  <div style="display: flex; flex-wrap: wrap; margin-top: 10px transition: all 0.5s;" class="mt-4">
    <v-chip
      v-for="item in selectedItems"
      :key="item"
      class="mx-1 mb-2 pr-2 pl-5"
      style="display: inline-flex; justify-content: space-between; align-items: center"
    >
      {{ descriptionFor(item) || item }}
      <img :width="24" :src="'/content/svgs/close-pills.svg'" @click="removeItem(item)" />
    </v-chip>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'SSelectorChips',
  props: {
    removeItem: {
      type: Function as PropType<(item: any) => void>,
      required: true
    },
    selectedItems: {
      type: Array as PropType<any[]>,
      required: true
    },
    descriptionFor: {
      type: Function as PropType<(item: any) => string>,
      required: true
    }
  }
});
</script>

<style lang="scss" scoped>
::v-deep .v-chip__content {
  color: #606061 !important;
  font-size: 14px !important;
  font-family: Manrope !important;
  font-weight: 400 !important;
  word-wrap: break-word !important;
  height: 31px !important;
  width: fit-content !important;
}
::v-deep .theme--light.v-chip:not(.v-chip--active) {
  background-color: #ececef !important;
}
</style>
