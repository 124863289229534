var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("SModalCentered", {
    attrs: {
      title: _vm.$t("stemdoerDetail.experienceModal.title"),
      isOpen: _vm.isOpen,
      closeModal: _vm.closeModal,
      customProps: { bodyPaddingY: 0, fullWidthFooter: true },
      persistent: true,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { staticClass: "d-flex flex-column" }, [
              _c("span", {
                staticClass: "text-body-1 text--text text--base mb-4",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("stemdoerDetail.experienceModal.subtitle")
                  ),
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c("SInputText", {
                    attrs: {
                      label: _vm
                        .$t("stemdoerDetail.experienceModal.position")
                        .toString(),
                      placeholder: "DevOps Engineer",
                      errorMessage:
                        _vm.vuelidate.position.$error &&
                        _vm.vuelidate.position.required.$invalid
                          ? _vm.$t("formMessage.required").toString()
                          : undefined,
                    },
                    model: {
                      value: _vm.experienceForm.position,
                      callback: function ($$v) {
                        _vm.$set(_vm.experienceForm, "position", $$v)
                      },
                      expression: "experienceForm.position",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c("SInputText", {
                    attrs: {
                      label: _vm
                        .$t("stemdoerDetail.experienceModal.company")
                        .toString(),
                      placeholder: "Stemdo",
                      errorMessage:
                        _vm.vuelidate.company.$error &&
                        _vm.vuelidate.company.required.$invalid
                          ? _vm.$t("formMessage.required").toString()
                          : undefined,
                    },
                    model: {
                      value: _vm.experienceForm.company,
                      callback: function ($$v) {
                        _vm.$set(_vm.experienceForm, "company", $$v)
                      },
                      expression: "experienceForm.company",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "d-flex flex-row mb-4",
                  staticStyle: { gap: "16px" },
                },
                [
                  _c("SInputDate", {
                    attrs: {
                      label: _vm
                        .$t("stemdoerDetail.experienceModal.startDate")
                        .toString(),
                      placeholder: _vm
                        .$t(
                          "stemdoerDetail.experienceModal.startDatePlaceholder"
                        )
                        .toString(),
                      errorMessage:
                        _vm.vuelidate.startDate.$error &&
                        _vm.vuelidate.startDate.required.$invalid
                          ? _vm.$t("formMessage.required").toString()
                          : undefined,
                    },
                    model: {
                      value: _vm.experienceForm.startDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.experienceForm, "startDate", $$v)
                      },
                      expression: "experienceForm.startDate",
                    },
                  }),
                  _vm._v(" "),
                  _c("SInputDate", {
                    attrs: {
                      label: _vm
                        .$t("stemdoerDetail.experienceModal.endDate")
                        .toString(),
                      placeholder: _vm
                        .$t("stemdoerDetail.experienceModal.endDatePlaceholder")
                        .toString(),
                      errorMessage:
                        _vm.vuelidate.endDate.$error &&
                        _vm.vuelidate.endDate.required.$invalid
                          ? _vm.$t("formMessage.required").toString()
                          : undefined,
                    },
                    model: {
                      value: _vm.experienceForm.endDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.experienceForm, "endDate", $$v)
                      },
                      expression: "experienceForm.endDate",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c("SSelectorMultiple", {
                    attrs: {
                      mode: "chip",
                      label: _vm
                        .$t("stemdoerDetail.experienceModal.competencies")
                        .toString(),
                      placeholder: _vm
                        .$t(
                          "stemdoerDetail.experienceModal.competenciesPlaceholder"
                        )
                        .toString(),
                      items: _vm.competenciesItems,
                      errorMessage:
                        _vm.vuelidate.competencies.$error &&
                        _vm.vuelidate.competencies.required.$invalid
                          ? _vm.$t("formMessage.required").toString()
                          : undefined,
                    },
                    model: {
                      value: _vm.experienceForm.competencies,
                      callback: function ($$v) {
                        _vm.$set(_vm.experienceForm, "competencies", $$v)
                      },
                      expression: "experienceForm.competencies",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("SInputTextarea", {
                    attrs: {
                      label: _vm
                        .$t("stemdoerDetail.experienceModal.description")
                        .toString(),
                      placeholder: _vm
                        .$t(
                          "stemdoerDetail.experienceModal.descriptionPlaceholder"
                        )
                        .toString(),
                      errorMessage:
                        _vm.vuelidate.description.$error &&
                        _vm.vuelidate.description.required.$invalid
                          ? _vm.$t("formMessage.required").toString()
                          : undefined,
                    },
                    model: {
                      value: _vm.experienceForm.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.experienceForm, "description", $$v)
                      },
                      expression: "experienceForm.description",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c(
              "v-container",
              { staticClass: "pa-0" },
              [
                _c(
                  "v-row",
                  { attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { staticClass: "mr-6" },
                      [
                        _c("SButton", {
                          attrs: {
                            action: _vm.closeModal,
                            btnStyle: "tertiary",
                            sText: _vm.$t(
                              "stemdoerDetail.experienceModal.cancelButton"
                            ),
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      [
                        _c("SButton", {
                          attrs: {
                            action: _vm.onSaveClick,
                            sText: _vm.$t(
                              "stemdoerDetail.experienceModal.saveButton"
                            ),
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }