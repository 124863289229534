<!-- eslint-disable prettier/prettier -->
<template>
  <v-dialog
    data-cy="dialog-stemdoer"
    v-model="isDialogOpen"
    max-width="70%"
    class="bg-white"
    @input="resetOverflow"
    @click:outside="closeDialog"
  >
    <div data-cy="btn-exit-wrapper" class="btn-exit" style="width: 100%">
      <v-btn
        data-cy="btn-close-dialog"
        style="background-color: #f9f9f9 !important; z-index: 3"
        fab
        dark
        x-small
        color="#DBDBE0"
        elevation="0"
        @click="closeDialog"
      >
        <img data-cy="img-close-tag" src="/content/svgs/close-tag.svg" width="100%" style="opacity: 0.8; transform: scale(1.3)" />
      </v-btn>
    </div>
    <StemdoerDetail data-cy="stemdoer-detail" :stemdoer="stemdoer" :onAddToCart="handleAddToCart" :rate="rate" :rates="rates" />
  </v-dialog>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import StemdoerDetail from './StemdoerDetail.vue';
import { Stemdoer } from '@/shared/model/stemdoer.model';
import { IRate } from '@/shared/model/rate.model';

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true
  },
  onClose: {
    type: Function,
    required: true
  },
  stemdoer: {
    type: Object as () => Stemdoer,
    required: true
  },
  rate: {
    type: Number
  },
  rates: {
    type: Array as () => IRate[]
  },
  onAddToCart: {
    type: Function,
    required: false
  }
});
const isDialogOpen = ref(props.isOpen);

watch(
  () => props.isOpen,
  value => {
    isDialogOpen.value = value;
  }
);

const closeDialog = () => {
  props.onClose();
};

const handleAddToCart = (stemdoerId: string) => {
  if (props.onAddToCart) {
    props.onAddToCart(stemdoerId);
    props.onClose();
  }
};

const resetOverflow = () => {
  document.documentElement.style.overflow = 'auto';
};
</script>

<style lang="scss" scoped>
::v-deep .v-dialog--active {
  height: 100% !important;
  max-height: 100% !important;
  min-height: 100% !important;
  margin: 0px !important;
  position: fixed !important;
  top: 0% !important;
  right: 0% !important;
  background-color: white;
}

.btn-exit {
  position: fixed;
  margin-top: 2em;
  margin-left: -3em;
  z-index: -1;
  background-color: #f9f9f9;
  padding: 0.5em;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.v-dialog__content {
  justify-content: flex-end !important;
  margin-left: 20% !important;
}
</style>
