var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "w-100 d-flex flex-column flex-fill" },
    [
      _vm.label
        ? _c("span", {
            staticClass: "text-body-2-bold text--text text--lighten-1 mb-2",
            domProps: { textContent: _vm._s(_vm.label) },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-menu",
        {
          attrs: {
            transition: "slide-x-transition",
            "offset-y": "",
            "close-on-content-click": false,
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "div",
                    _vm._g(
                      _vm._b(
                        {
                          class: {
                            "d-flex": true,
                            "input-wrapper": true,
                            "error-wrapper-style": _vm.errorMessage,
                            "default-wrapper-style": !_vm.errorMessage,
                          },
                        },
                        "div",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("img", {
                        attrs: {
                          width: "24px",
                          src: "/content/svgs/calendar.svg",
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        class: {
                          "w-100": true,
                          "error-input-style": _vm.errorMessage,
                          "default-input-style": !_vm.errorMessage,
                        },
                        attrs: {
                          type: "text",
                          placeholder: _vm.placeholder,
                          readonly: "",
                        },
                        domProps: {
                          value: _vm.date
                            ? _vm
                                .$d(Date.parse(_vm.date), "long")
                                .toString()
                                .replace(/ de /g, " ")
                            : _vm.date,
                        },
                      }),
                    ]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.isDatePickerOpen,
            callback: function ($$v) {
              _vm.isDatePickerOpen = $$v
            },
            expression: "isDatePickerOpen",
          },
        },
        [
          _vm._v(" "),
          _c("v-date-picker", {
            attrs: {
              "first-day-of-week": 1,
              locale: "es-ES",
              "no-title": "",
              scrollable: "",
            },
            on: { change: _vm.onDateChange },
            model: {
              value: _vm.date,
              callback: function ($$v) {
                _vm.date = $$v
              },
              expression: "date",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.errorMessage
        ? _c("span", {
            staticClass: "text-body-2-bold quaternary--text text--base mt-1",
            domProps: { textContent: _vm._s(_vm.errorMessage) },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }