var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "mt-4",
      staticStyle: {
        display: "flex",
        "flex-wrap": "wrap",
        "margin-top": "10px transition: all 0.5s",
      },
    },
    _vm._l(_vm.selectedItems, function (item) {
      return _c(
        "v-chip",
        {
          key: item,
          staticClass: "mx-1 mb-2 pr-2 pl-5",
          staticStyle: {
            display: "inline-flex",
            "justify-content": "space-between",
            "align-items": "center",
          },
        },
        [
          _vm._v(
            "\n    " + _vm._s(_vm.descriptionFor(item) || item) + "\n    "
          ),
          _c("img", {
            attrs: { width: 24, src: "/content/svgs/close-pills.svg" },
            on: {
              click: function ($event) {
                return _vm.removeItem(item)
              },
            },
          }),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }