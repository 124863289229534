var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "SHeaderSection",
    {
      attrs: {
        "data-cy": "header-section-team-request-detail",
        title: _vm.teamRequest.name,
      },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function () {
            return [
              _c("SPopUp", {
                attrs: {
                  "data-cy": "popup-info",
                  isOpen: _vm.sPopUpData.open,
                  sText: _vm.sPopUpData.text,
                  isValid: true,
                },
                on: {
                  close: () => {
                    _vm.sPopUpData.open = false
                  },
                },
              }),
              _vm._v(" "),
              _c("SShareLink", {
                staticClass: "my-4",
                attrs: {
                  "data-cy": "share-link-team-request",
                  type: "teamRequest",
                  id: _vm.teamRequestId,
                  onLinkCopied: _vm.openPopUp,
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm.existStemdoerNotAvailable
        ? _c(
            "div",
            {
              staticClass: "no-available-stemdoers-banner",
              attrs: { "data-cy": "banner-no-available-stemdoers" },
            },
            [
              _c(
                "p",
                {
                  staticClass: "text-body-1 mb-0",
                  attrs: { "data-cy": "p-no-available-stemdoers-message" },
                },
                [
                  _vm._v(
                    "\n      Los candidatos de esta solicitud ya no están disponibles. Si lo deseas puedes volver a la vista explorar para seleccionar nuevos o\n      denegar la solicitud.\n      "
                  ),
                  _c(
                    "span",
                    {
                      staticClass:
                        "no-available-stemdoers-banner-go-explore text-body-1-bold",
                      attrs: { "data-cy": "link-go-explore" },
                      on: { click: _vm.gotoExplore },
                    },
                    [_vm._v("\n        Ir a explorar\n      ")]
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "v-container",
        {
          staticClass: "px-0 pt-10 pb-0 mx-0",
          style: { minWidth: "100%" },
          attrs: { "data-cy": "container-team-request-detail" },
        },
        [
          _vm.isUserClient
            ? _c(
                "v-row",
                {
                  staticClass: "mb-6",
                  attrs: { "data-cy": "row-client-status", "no-gutters": "" },
                },
                [
                  _c(
                    "v-col",
                    [
                      _c("HeaderTeamRequestStatus", {
                        attrs: {
                          "data-cy": "header-team-request-status",
                          teamRequest: _vm.teamRequest,
                          teamRequestStatus: _vm.extendedTeamRequestStatus,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-row",
            {
              attrs: {
                "data-cy": "row-team-request-content",
                "no-gutters": "",
              },
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "pa-0",
                  attrs: { "data-cy": "col-stemdoer-list" },
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "mb-4",
                      attrs: {
                        "data-cy": "row-stemdoer-list-title",
                        "no-gutters": "",
                      },
                    },
                    [
                      _c("v-col", [
                        _c("span", {
                          staticClass: "text-h4 text--text text--base",
                          attrs: { "data-cy": "stemdoer-list-title" },
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("teamRequestDetail.stemdoerList.title")
                            ),
                          },
                        }),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass: "mb-6",
                      attrs: {
                        "data-cy": "row-stemdoer-list",
                        "no-gutters": "",
                      },
                    },
                    [
                      _c(
                        "v-col",
                        [
                          _c("StemdoerList", {
                            attrs: {
                              "data-cy": "component-stemdoer-list",
                              stemdoerRates: _vm.teamRequest.stemdoerRates,
                              teamRequestStatus: _vm.extendedTeamRequestStatus,
                              onStemdoerRateChange: _vm.updateStemdoerRate,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  staticClass: "pa-0 ml-6 mb-4",
                  attrs: { "data-cy": "col-team-request-overview", cols: "5" },
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "mb-4",
                      attrs: {
                        "data-cy": "row-overview-title",
                        "no-gutters": "",
                      },
                    },
                    [
                      _c("v-col", [
                        _c("span", {
                          staticClass: "text-h4 text--text text--base",
                          attrs: { "data-cy": "overview-title" },
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("teamRequestDetail.overview.details")
                            ),
                          },
                        }),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      attrs: {
                        "data-cy": "row-team-request-overview",
                        "no-gutters": "",
                      },
                    },
                    [
                      _c(
                        "v-col",
                        [
                          _c("TeamRequestOverview", {
                            attrs: {
                              "data-cy": "component-team-request-overview",
                              teamRequest: _vm.teamRequest,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass: "mt-6",
                      attrs: {
                        "data-cy": "row-buttons-group",
                        "no-gutters": "",
                      },
                    },
                    [
                      _c(
                        "v-col",
                        [
                          _c("ButtonsGroup", {
                            attrs: {
                              "data-cy": "buttons-group-team-request",
                              teamRequest: _vm.teamRequest,
                              teamRequestStatus: _vm.extendedTeamRequestStatus,
                              refreshTeamRequest: _vm.refreshTeamRequest,
                              onSuccess: _vm.handleSuccess,
                              onError: _vm.handleError,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("SModalCentered", {
        attrs: {
          "data-cy": "modal-available-stemdoers",
          isOpen: _vm.isAvailableStemdoersModalOpen,
          title: _vm.$t("managerTeamDetail.modalStemoderNoAvailable.title"),
          maxWidth: "500px",
          closeModal: _vm.closeAvailableStemdoersModal,
          customProps: { bodyPaddingY: 0, fullWidthFooter: true },
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "span",
                  {
                    staticClass: "text-body-1 text--text base--base",
                    attrs: { "data-cy": "modal-available-stemdoers-message" },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t(
                            "managerTeamDetail.modalStemoderNoAvailable.message"
                          )
                        ) +
                        "\n      "
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "v-container",
                  {
                    staticClass: "p-0",
                    attrs: { "data-cy": "container-modal-footer" },
                  },
                  [
                    _c(
                      "v-row",
                      {
                        attrs: {
                          "data-cy": "row-modal-footer",
                          "no-gutters": "",
                        },
                      },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "pr-2",
                            attrs: {
                              "data-cy": "col-modal-continue",
                              cols: "6",
                            },
                          },
                          [
                            _c("sButton", {
                              attrs: {
                                "data-cy": "btn-modal-continue",
                                btnStyle: "secondary",
                                sText:
                                  "managerTeamDetail.modalStemoderNoAvailable.buttonContinue",
                                action: _vm.handleContinueAction,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          {
                            staticClass: "pl-2",
                            attrs: {
                              "data-cy": "col-modal-explore",
                              cols: "6",
                            },
                          },
                          [
                            _c("sButton", {
                              attrs: {
                                "data-cy": "btn-modal-explore",
                                btnStyle: "primary",
                                sText:
                                  "managerTeamDetail.modalStemoderNoAvailable.buttonExplore",
                                action: _vm.gotoExplore,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("SPopUp", {
        staticClass: "popUp",
        attrs: {
          "data-cy": "popup-error",
          isOpen: _vm.errorPopUp,
          sText: _vm.$t("label.teams.selected.errorPopUp"),
          isValid: !_vm.errorPopUp,
        },
        on: { close: _vm.handleClosePopUp },
      }),
      _vm._v(" "),
      _c("SPopUp", {
        staticClass: "popUp",
        attrs: {
          "data-cy": "popup-success",
          isOpen: _vm.successPopUp,
          sText: _vm.successMessage,
          isValid: _vm.successPopUp,
        },
        on: { close: _vm.handleClosePopUp },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }