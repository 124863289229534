import { defineComponent, ref, PropType } from 'vue';

export interface ISelectState {
  id?: string;
  desc?: string;
}

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    items: {
      type: Array as PropType<ISelectState>,
      required: true
    },
    onChange: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const selectedItem = ref<ISelectState | undefined>(undefined);
    const labelDisplay = ref('');

    const handleChange = (newSelectedItem: ISelectState | null) => {
      selectedItem.value = newSelectedItem || undefined;
      props.onChange(newSelectedItem.id);
      setLabelDisplay();
    };

    const isItemSelected = item => {
      setLabelDisplay();
      if (!selectedItem.value) return false;
      return selectedItem.value.id === item.id;
    };

    const setLabelDisplay = () => {
      const itemDesc = selectedItem.value?.desc;
      labelDisplay.value = `${itemDesc}`;
    };

    return { selectedItem, labelDisplay, handleChange, isItemSelected };
  }
});
