import { ICohort } from '@/shared/model/cohort.model';
import { IVertical } from '@/shared/model/vertical.model';
import { IStemdoerTeam } from './stemdoer-team.model';
import { IRate } from './rate.model';
import { IExperience } from './experience.model';
import { ITechnologyScore, TechnologyScores } from './technology-score.model';
import { IAvailability } from './availability.model';
import { IStemdoerCertification } from './stemdoerCertification.model';
import { differenceInDays, parseISO } from 'date-fns';
import { ILanguage } from './language.model';

export interface IStemdoer {
  id?: string;
  stemdoId?: string;
  name?: string;
  surname?: string;
  position?: string;
  office?: string;
  seniority?: string;
  rate?: number;
  email?: string;
  hireDate?: Date;
  mbti?: string | null;
  education?: string | null;
  softSkills?: number[] | null;
  languages?: ILanguage[] | null;
  teamRequests?: string[] | null;
  experience?: IExperience[];
  cohorts?: ICohort[] | null;
  vertical?: IVertical | null;
  stemdoerTeams?: IStemdoerTeam[] | null;
  technologyScores?: ITechnologyScore[] | null;
  certifications?: IStemdoerCertification[] | null;
  hide?: boolean | null;
  terminated?: boolean | null;
  status?: string;
  availability?: IAvailability | null;
  canBeAddedToCart?: boolean;
}

export class Stemdoer implements IStemdoer {
  id?: string;
  stemdoId?: string;
  name?: string;
  surname?: string;
  position?: string;
  office?: string;
  seniority?: string;
  rate?: number;
  email?: string;
  hireDate?: Date;
  mbti?: string;
  education?: string;
  softSkills?: number[];
  languages?: ILanguage[];
  teamRequests?: string[];
  experience?: IExperience[];
  cohorts?: ICohort[];
  vertical?: IVertical;
  stemdoerTeams?: IStemdoerTeam[];
  technologyScores?: TechnologyScores;
  certifications?: IStemdoerCertification[];
  hide?: boolean;
  terminated?: boolean;
  status?: string;
  availability?: IAvailability;
  canBeAddedToCart?: boolean;

  constructor(data: IStemdoer) {
    this.id = data?.id;
    this.stemdoId = data.stemdoId;
    this.name = data.name;
    this.surname = data.surname;
    this.position = data.position;
    this.office = data.office;
    this.seniority = data.seniority;
    this.rate = data.rate;
    this.email = data.email;
    this.hireDate = data.hireDate;
    this.mbti = data.mbti;
    this.education = data.education;
    this.softSkills = data.softSkills;
    this.languages = data.languages;
    this.teamRequests = data.teamRequests;
    this.experience = data.experience;
    this.cohorts = data.cohorts;
    this.vertical = data.vertical;
    this.stemdoerTeams = data.stemdoerTeams;
    this.technologyScores = TechnologyScores.fromI(data.technologyScores);
    this.certifications = data.certifications;
    this.hide = data.hide;
    this.terminated = data.terminated;
    this.status = data.status;
    this.availability = data.availability;
    this.canBeAddedToCart = this._canBeAddedToCart();
  }

  static empty = (): Stemdoer => {
    return new Stemdoer({
      id: null,
      stemdoId: null,
      name: null,
      surname: null,
      position: null,
      office: null,
      seniority: null,
      rate: null,
      email: null,
      hireDate: null,
      mbti: null,
      education: null,
      softSkills: null,
      languages: null,
      teamRequests: null,
      experience: null,
      cohorts: null,
      vertical: null,
      stemdoerTeams: null,
      technologyScores: null,
      certifications: null,
      hide: null,
      terminated: null,
      status: null,
      availability: null,
      canBeAddedToCart: null
    });
  };

  isAvailable = () => {
    return this.availability?.available;
  };

  setRate = (clientRates: IRate[]) => {
    const rate = clientRates.find(
      rate =>
        rate.seniority.localeCompare(this.seniority === 'MID_SENIOR' ? 'MID-SENIOR' : this.seniority, undefined, {
          sensitivity: 'base'
        }) === 0
    )?.value;
    this.rate = rate ? rate : 0;
  };

  sortTechnologyScoresDesc = () => {
    if (this.technologyScores) {
      this.technologyScores.orderByScoreDesc();
    }
  };

  _canBeAddedToCart = (date: Date = new Date()): boolean => {
    if (!this.availability) return false;
    if (this.isAvailable() && this.availability.availableAt === null) {
      return true;
    } else if (!this.isAvailable() && this.availability.availableAt === null) {
      return false;
    } else if (!this.isAvailable() && this.availability.availableAt !== null) {
      const availableDate = parseISO(this.availability.availableAt.toString());
      const diffInDays = differenceInDays(availableDate, date);
      return diffInDays <= 60;
    }
    return false;
  };
}

export class Stemdoers extends Array<Stemdoer> {
  constructor(data: Stemdoer[]) {
    super(...data);
  }

  static empty = () => {
    return new Stemdoers([]);
  };

  static from = (data: Stemdoer[]): Stemdoers => {
    return new Stemdoers(data.map(stemdoer => new Stemdoer(stemdoer)));
  };

  static fromI = (data: IStemdoer[]): Stemdoers => {
    return new Stemdoers(data.map(stemdoer => new Stemdoer(stemdoer)));
  };

  getById = (id: string): Stemdoer | undefined => {
    return this.find(stemdoer => stemdoer.id === id);
  };

  setRates = (clientRates: IRate[]) => {
    this.forEach(stemdoer => {
      stemdoer.setRate(clientRates);
    });
  };

  updateRate = (id: string, newRate: number) => {
    const stemdoer = this.getById(id);
    if (stemdoer) {
      stemdoer.rate = newRate;
    }
  };

  getRateTotal = () => {
    return this.reduce((total, s) => total + s.rate, 0);
  };

  getFilterByIds = (ids: string[]): Stemdoers => {
    return new Stemdoers(this.filter(stemdoer => !ids.includes(stemdoer.id)));
  };

  getFilterById = (id: string): Stemdoers => {
    return new Stemdoers(this.filter(stemdoer => stemdoer.id !== id));
  };
}
