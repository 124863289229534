var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "mx-0 pl-0 pt-0 pb-0",
      attrs: { fluid: "", "data-cy": "team-requests-filter-container" },
    },
    [
      _c(
        "v-card-title",
        { staticClass: "d-flex align-center filter-container" },
        [
          _c(
            "v-row",
            {
              staticClass: "d-flex align-center p-3",
              attrs: { "no-gutters": "" },
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "p-2",
                  attrs: { cols: "2", "data-cy": "filter-company-col" },
                },
                [
                  _c("SSelectorMultiple", {
                    attrs: {
                      mode: "floatingLabel",
                      label: _vm.$t("requests-filter-list.filter.client"),
                      items: _vm.companyItems,
                      "data-cy": "filter-company-selector",
                    },
                    model: {
                      value: _vm.companiesSelected,
                      callback: function ($$v) {
                        _vm.companiesSelected = $$v
                      },
                      expression: "companiesSelected",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  staticClass: "p-2",
                  attrs: { cols: "2", "data-cy": "filter-status-col" },
                },
                [
                  _c("SSelectorMultiple", {
                    attrs: {
                      mode: "floatingLabel",
                      label: _vm.$t("requests-filter-list.filter.status.title"),
                      items: _vm.statusItems,
                      "data-cy": "filter-status-selector",
                    },
                    model: {
                      value: _vm.statusSelected,
                      callback: function ($$v) {
                        _vm.statusSelected = $$v
                      },
                      expression: "statusSelected",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  staticClass: "p-2 pl-2 pr-2",
                  attrs: { cols: "8", "data-cy": "filter-search-col" },
                },
                [
                  _c("SSearch", {
                    attrs: {
                      "data-cy": "filter-search-input",
                      defaultText: _vm.storedSearch,
                      placeholder: _vm.$t("requests-filter-list.filter.search"),
                      onChange: _vm.onSearch,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }