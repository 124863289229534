import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    sText: {
      type: String as PropType<string>,
      required: true
    },
    isValid: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  },
  setup(_, { emit }) {
    const handleClose = () => {
      emit('close');
    };

    return {
      handleClose
    };
  }
});
