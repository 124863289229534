var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      staticClass: "bg-white",
      attrs: { "data-cy": "dialog-stemdoer", "max-width": "70%" },
      on: { input: _setup.resetOverflow, "click:outside": _setup.closeDialog },
      model: {
        value: _setup.isDialogOpen,
        callback: function ($$v) {
          _setup.isDialogOpen = $$v
        },
        expression: "isDialogOpen",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "btn-exit",
          staticStyle: { width: "100%" },
          attrs: { "data-cy": "btn-exit-wrapper" },
        },
        [
          _c(
            "v-btn",
            {
              staticStyle: {
                "background-color": "#f9f9f9 !important",
                "z-index": "3",
              },
              attrs: {
                "data-cy": "btn-close-dialog",
                fab: "",
                dark: "",
                "x-small": "",
                color: "#DBDBE0",
                elevation: "0",
              },
              on: { click: _setup.closeDialog },
            },
            [
              _c("img", {
                staticStyle: { opacity: "0.8", transform: "scale(1.3)" },
                attrs: {
                  "data-cy": "img-close-tag",
                  src: "/content/svgs/close-tag.svg",
                  width: "100%",
                },
              }),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(_setup.StemdoerDetail, {
        attrs: {
          "data-cy": "stemdoer-detail",
          stemdoer: _vm.stemdoer,
          onAddToCart: _setup.handleAddToCart,
          rate: _vm.rate,
          rates: _vm.rates,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }