var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "w-100 d-flex flex-column flex-fill" }, [
    _vm.label
      ? _c("span", {
          staticClass: "text-body-2-bold text--text text--lighten-1 mb-2",
          domProps: { textContent: _vm._s(_vm.label) },
        })
      : _vm._e(),
    _vm._v(" "),
    _c("input", {
      class: {
        "w-100": true,
        "error-style": _vm.errorMessage,
        "default-style": !_vm.errorMessage,
      },
      attrs: { type: "text", placeholder: _vm.placeholder },
      domProps: { value: _vm.value },
      on: {
        input: function ($event) {
          return _vm.$emit("input", $event.target.value)
        },
      },
    }),
    _vm._v(" "),
    _vm.errorMessage
      ? _c("span", {
          staticClass: "text-body-2-bold quaternary--text text--base mt-1",
          domProps: { textContent: _vm._s(_vm.errorMessage) },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }