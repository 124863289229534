import { defineComponent, inject, onMounted, ref } from 'vue';
import SHeaderSection from '@/components/stemdo-components/s-header-section/SHeaderSection.vue';
import StemdoerService from '@/sections/admin/entities/stemdoer/stemdoer.service';
import { Stemdoer } from '@/shared/model/stemdoer.model';
import SBoxStemdoerCompsAndCerts from '@/components/stemdo-components/s-box-stemdoer-comps-and-certs/SBoxStemdoerCompsAndCerts.vue';
import SBoxStemdoerExperience from '@/components/stemdo-components/s-box-stemdoer-experience/SBoxStemdoerExperience.vue';
import SBoxStemdoerEducation from '@/components/stemdo-components/s-box-stemdoer-education/SBoxStemdoerEducation.vue';
import StemdoerOverviewCard from './StemdoerOverviewCard.vue';
import ExperienceModal from './experienceModal/ExperienceModal.vue';
import { useRouter } from '@/plugins/router';
import { IExperience } from '@/shared/model/experience.model';
import SPopUp from '@/components/stemdo-components/s-pop-up/SPopUp.vue';
import { useI18N } from '@/plugins/i18n';

export default defineComponent({
  props: {
    stemdoerId: {
      type: String,
      required: true
    }
  },
  components: {
    SHeaderSection,
    SBoxStemdoerCompsAndCerts,
    SBoxStemdoerExperience,
    SBoxStemdoerEducation,
    StemdoerOverviewCard,
    ExperienceModal,
    SPopUp
  },
  setup(props) {
    const router = useRouter();
    const i18n = useI18N();
    const experienceModalMode = ref<'add' | 'edit'>('add');
    const experienceSelected = ref<IExperience>(undefined);
    const stemdoerService: StemdoerService = inject('stemdoerService');
    const stemdoer = ref<Stemdoer>(Stemdoer.empty());
    const isAddExperienceModalOpen = ref(false);
    const popUpData = ref({
      message: '',
      isOpen: false
    });

    onMounted(async () => {
      stemdoer.value = await getStemdoerDetail();
    });

    const getStemdoerDetail = async () => {
      try {
        const res = await stemdoerService.find(props.stemdoerId);
        return new Stemdoer(res);
      } catch (err) {
        router.push({ name: 'NotFoundError' });
      }
    };

    const openAddExperienceModal = experience => {
      if (experience) {
        experienceModalMode.value = 'edit';
        experienceSelected.value = experience;
      } else {
        experienceModalMode.value = 'add';
      }
      isAddExperienceModalOpen.value = true;
    };

    const closeAddExperienceModal = () => {
      experienceSelected.value = undefined;
      isAddExperienceModalOpen.value = false;
    };

    const saveExperience = (experience: IExperience) => {
      if (experienceModalMode.value === 'add') {
        stemdoerService.createExperience(props.stemdoerId, experience).then(async () => {
          stemdoer.value = await getStemdoerDetail();
          closeAddExperienceModal();
          popUpData.value = {
            message: i18n.t('stemdoerDetail.popUp.saveConfirmation').toString(),
            isOpen: true
          };
        });
      }
      if (experienceModalMode.value === 'edit') {
        stemdoerService.partialUpdateExperience(props.stemdoerId, experience.id, experience).then(async () => {
          stemdoer.value = await getStemdoerDetail();
          closeAddExperienceModal();
          popUpData.value = {
            message: i18n.t('stemdoerDetail.popUp.editConfirmation').toString(),
            isOpen: true
          };
        });
      }
    };

    return {
      stemdoer,
      isAddExperienceModalOpen,
      experienceModalMode,
      experienceSelected,
      popUpData,
      saveExperience,
      openAddExperienceModal,
      closeAddExperienceModal
    };
  }
});
