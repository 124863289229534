var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "pa-6",
      staticStyle: {
        "min-width": "282px",
        "background-color": "var(--v-background-darken1)",
        "border-radius": "4px",
      },
    },
    [
      _vm.stemdoer.stemdoId
        ? _c(
            "div",
            { staticClass: "image-container mb-6" },
            [
              _c("SAvatarStemdoer", {
                attrs: { stemdoId: _vm.stemdoer.stemdoId, size: "75px" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex flex-column align-items-center mb-8" }, [
        _c(
          "span",
          { staticClass: "text-body-1-bold text--text text--base mb-2" },
          [
            _vm._v(
              " " + _vm._s(_vm.stemdoer.name + " " + _vm.stemdoer.surname) + " "
            ),
          ]
        ),
        _vm._v(" "),
        _c("span", { staticClass: "text-body-1-bold text--text text--base" }, [
          _vm._v(" " + _vm._s(_vm.stemdoer.position) + " "),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex flex-column" }, [
        _c("div", { staticClass: "d-flex flex-column mb-4" }, [
          _c("span", {
            staticClass: "text-body-2-bold text--text text--lighten-1 mb-2",
            domProps: { textContent: _vm._s(_vm.$t("stemdoerDetail.email")) },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "text-body-1 text--text text--base" }, [
            _vm._v(" " + _vm._s(_vm.stemdoer.email) + " "),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex flex-column mb-4" }, [
          _c("span", {
            staticClass: "text-body-2-bold text--text text--lighten-1 mb-2",
            domProps: {
              textContent: _vm._s(_vm.$t("stemdoerDetail.hireDate")),
            },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "text-body-1 text--text text--base" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$d(new Date(_vm.stemdoer.hireDate), "twoDigit")) +
                " "
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.stemdoer.languages && _vm.stemdoer.languages.length > 0
          ? _c(
              "div",
              { staticClass: "d-flex flex-column mb-4" },
              [
                _c("span", {
                  staticClass:
                    "text-body-2-bold text--text text--lighten-1 mb-2",
                  domProps: {
                    textContent: _vm._s(_vm.$t("stemdoerDetail.languages")),
                  },
                }),
                _vm._v(" "),
                _vm._l(
                  _vm.getLanguageBadges(_vm.stemdoer.languages),
                  function (language) {
                    return _c(
                      "div",
                      {
                        key: language.name,
                        staticClass: "d-flex flex-column mb-2",
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "text-body-1 text--text text--base" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(language.name) +
                                " " +
                                _vm._s(language.level) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    )
                  }
                ),
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.stemdoer.mbti && _vm.stemdoer.mbti != ""
          ? _c("div", { staticClass: "d-flex flex-column mb-4" }, [
              _c("span", {
                staticClass: "text-body-2-bold text--text text--lighten-1 mb-2",
                domProps: {
                  textContent: _vm._s(_vm.$t("stemdoerDetail.mbti")),
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "text-body-1 text--text text--base" }, [
                _vm._v(" " + _vm._s(_vm.stemdoer.mbti) + " "),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.platformSeniority != null
          ? _c("div", { staticClass: "d-flex flex-column" }, [
              _c("span", {
                staticClass: "text-body-2-bold text--text text--lighten-1 mb-2",
                domProps: {
                  textContent: _vm._s(_vm.$t("stemdoerDetail.seniority")),
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "text-body-1 text--text text--base" }, [
                _vm._v(" " + _vm._s(_vm.platformSeniority) + " "),
              ]),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }