var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "SHeaderSection",
    { attrs: { title: _vm.stemdoer.name + " " + _vm.stemdoer.surname } },
    [
      _c("SPopUp", {
        staticClass: "mt-n12",
        attrs: {
          isOpen: _vm.popUpData.isOpen,
          sText: _vm.popUpData.message,
          isValid: true,
        },
        on: {
          close: () => {
            _vm.popUpData.isOpen = false
          },
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-flex pt-10",
          staticStyle: { "background-color": "white" },
        },
        [
          _c(
            "div",
            [_c("StemdoerOverviewCard", { attrs: { stemdoer: _vm.stemdoer } })],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "w-100 d-flex flex-column ml-8" }, [
            _c(
              "div",
              [
                _c("SBoxStemdoerCompsAndCerts", {
                  attrs: { stemdoer: _vm.stemdoer },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-8" },
              [
                _c("SBoxStemdoerExperience", {
                  attrs: {
                    stemdoer: _vm.stemdoer,
                    onAddExperienceClick: _vm.openAddExperienceModal,
                    onEditExperienceClick: _vm.openAddExperienceModal,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-8" },
              [
                _c("SBoxStemdoerEducation", {
                  attrs: { stemdoer: _vm.stemdoer },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("ExperienceModal", {
        attrs: {
          isOpen: _vm.isAddExperienceModalOpen,
          experience: _vm.experienceSelected,
          onExperienceSave: _vm.saveExperience,
          onCloseModal: _vm.closeAddExperienceModal,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }