import { defineComponent, ref, PropType, onMounted, watch, computed } from 'vue';
import SSelectorChips from './SSelectorChips.vue';

export interface ISelectState {
  id?: string;
  desc?: string;
}

export default defineComponent({
  components: {
    SSelectorChips
  },
  props: {
    value: {
      type: Array as PropType<string[]>,
      required: false
    },
    mode: {
      type: String as PropType<'default' | 'floatingLabel' | 'chip'>,
      default: 'default'
    },
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    items: {
      type: Array as PropType<ISelectState[]>,
      required: true
    },
    errorMessage: {
      type: String,
      required: false
    }
  },
  setup(props, { emit }) {
    const selectorItems = computed<ISelectState[]>(() => {
      return Array.from(new Set(props.items.map(item => item.id))).map(id => props.items.find(item => item.id === id));
    });
    const selectedItems = ref<string[]>([]);
    const areAllSelected = computed<boolean>(() => selectedItems.value.length === selectorItems.value.length - 1);
    const selectionSummary = ref('');

    onMounted(() => {
      selectedItems.value = selectedItemsFor(props.value);
      if (props.mode === 'floatingLabel' && props.label === undefined) {
        throw new Error('Floating label mode requires a label prop');
      }
    });

    watch(
      () => props.value,
      value => {
        selectedItems.value = selectedItemsFor(value);
      }
    );

    watch(
      () => props.items,
      value => {
        selectedItems.value = selectedItemsFor(props.value);
      }
    );

    watch(selectedItems, () => {
      updateSelectionSummary();
    });

    const handleChange = (newSelectedItems: any) => {
      const updatedSelectedItems = selectedItemsFor(newSelectedItems);
      emit('input', updatedSelectedItems);
    };

    const selectedItemsFor = (newSelectedItems: any) => {
      const areAllItemsSelected = newSelectedItems.length === selectorItems.value.length;

      if (newSelectedItems.includes('VIEW_ALL')) {
        if (areAllItemsSelected) {
          return [];
        } else {
          return selectorItems.value.filter((item: ISelectState) => item.id !== 'VIEW_ALL').map((item: ISelectState) => item.id);
        }
      } else {
        if (areAllItemsSelected) {
          return selectorItems.value
            .filter((item: ISelectState) => !newSelectedItems.includes(item.id))
            .map((item: ISelectState) => item.id);
        } else {
          return newSelectedItems;
        }
      }
    };

    const isSelected = (itemId: string) => {
      return selectedItems.value.some((selectedItem: any) => selectedItem === itemId);
    };

    const descriptionFor = (itemId: string) => {
      const item = props.items.find(item => item.id === itemId);
      return item ? item.desc : item;
    };

    const updateSelectionSummary = () => {
      if (selectedItems.value.length === 0) {
        if (props.mode === 'floatingLabel') selectionSummary.value = props.label.toString();
        if (props.mode === 'default') selectionSummary.value = props.placeholder.toString();
        return;
      }
      if (props.mode === 'floatingLabel') selectionSummary.value = `${props.label.toString()} · ${selectedItems.value.length}`;
      if (props.mode === 'default') selectionSummary.value = `${props.placeholder.toString()} · ${selectedItems.value.length}`;
    };

    const handleFocus = () => {
      selectionSummary.value = '';
    };

    const handleBlur = () => {
      updateSelectionSummary();
    };

    const removeItem = (itemId: string) => {
      selectedItems.value = selectedItems.value.filter((selectedItem: any) => selectedItem !== itemId);
    };

    return {
      removeItem,
      selectedItems,
      selectionSummary,
      areAllSelected,
      descriptionFor,
      handleChange,
      handleFocus,
      isSelected,
      handleBlur
    };
  }
});
