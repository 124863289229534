import { defineComponent, onMounted, ref, watch } from 'vue';
import { useI18N } from '@/plugins/i18n';
import { Stemdoer } from '@/shared/model/stemdoer.model';
import SReadMore from '@/components/stemdo-components/s-read-more/SReadMore.vue';
import SLanguageIcon from '@/components/stemdo-components/s-language-icon/SLanguageIcon.vue';
import { ExperienceCollection } from '@/shared/model/experience-collection.model';

export default defineComponent({
  props: {
    stemdoer: {
      type: Object as () => Stemdoer,
      required: true
    },
    onAddExperienceClick: {
      type: Function,
      default: undefined
    },
    onEditExperienceClick: {
      type: Function,
      default: undefined
    }
  },
  components: {
    SReadMore,
    SLanguageIcon
  },
  setup(props) {
    const i18n = useI18N();
    const stemdoerMutable = ref<Stemdoer>(new Stemdoer(props.stemdoer));

    onMounted(() => {
      if (stemdoerMutable.value.experience) {
        const experienceCollection = new ExperienceCollection(stemdoerMutable.value.experience);
        stemdoerMutable.value.experience = experienceCollection.groupByCompany();
      }
    });

    watch(
      () => props.stemdoer,
      value => {
        stemdoerMutable.value = new Stemdoer(value);
        if (stemdoerMutable.value.experience) {
          const experienceCollection = new ExperienceCollection(stemdoerMutable.value.experience);
          stemdoerMutable.value.experience = experienceCollection.groupByCompany();
        }
      }
    );

    const isStemdoExp = company => {
      if (company == null) return false;
      return company.toLowerCase() == 'stemdo';
    };

    const getTotalTime = time => {
      const years = Math.floor(time / 12);
      const months = time % 12;
      let totalTime = '';
      if (years !== 0) {
        totalTime += years + ' ' + (years > 1 ? i18n.t('explore.stemdoer-detail.years') : i18n.t('explore.stemdoer-detail.year')) + ' ';
      }
      if (months !== 0) {
        totalTime += months + ' ' + (months > 1 ? i18n.t('explore.stemdoer-detail.months') : i18n.t('explore.stemdoer-detail.month')) + ' ';
      }
      return totalTime.trim();
    };

    const sectorFormatted = (text: string | null | undefined) => {
      if (text == null) {
        return '';
      }
      return `(${text.toUpperCase()})`;
    };

    const getTech = (techs, limit?) => {
      if (techs) {
        const techList = techs.split(';').map(word => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        });
        if (limit === undefined) return techList.slice(0, limit);
        return techList;
      } else {
        return [];
      }
    };

    return { stemdoerMutable, isStemdoExp, getTotalTime, sectorFormatted, getTech };
  }
});
