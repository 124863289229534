import { Authority } from '@/shared/security/authority';
import StemdoerPage from '@/pages/shared/stemdoer/StemdoerPage.vue';
import StemdoerDetailPage from '@/pages/client/stemdoer/StemdoerDetailPage.vue';
import TeamRequestsDetailPage from '@/pages/client/team-requests-detail/TeamRequestsDetailPage.vue';

export default [
  {
    path: '/stemdoer/:id',
    name: 'stemdoerById',
    component: StemdoerPage,
    props: (route: { params: { id: any } }) => ({ stemdoerId: route.params.id } as { stemdoerId: string }),
    meta: { authorities: [Authority.CLIENT, Authority.GESTOR, Authority.ADMIN] }
  },
  {
    path: '/stemdoer/:id',
    name: 'stemdoerByIdClient',
    component: StemdoerDetailPage,
    props: (route: { params: { id: any } }) => ({ stemdoerId: route.params.id } as { stemdoerId: string }),
    meta: { authorities: [Authority.CLIENT, Authority.GESTOR, Authority.ADMIN] }
  },
  {
    path: '/team-requests/:id',
    name: 'teamRequestByClientId',
    component: TeamRequestsDetailPage,
    props: (route: { params: { id: any } }) => ({ teamRequestId: route.params.id } as { teamRequestId: string }),
    meta: { authorities: [Authority.CLIENT, Authority.GESTOR, Authority.ADMIN] }
  }
];
