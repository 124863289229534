import { defineComponent } from 'vue';
import { Stemdoer } from '@/shared/model/stemdoer.model';

export default defineComponent({
  props: {
    stemdoer: {
      type: Object as () => Stemdoer,
      required: true
    }
  },
  setup() {
    return {};
  }
});
