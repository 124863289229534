import { IStemdoer, Stemdoer } from '@/shared/model/stemdoer.model';
import SAvatarStemdoer from '@/components/stemdo-components/s-avatar-stemdoer/SAvatarStemdoer.vue';
import SButton from '@/components/stemdo-components/s-button/SButton.vue';
import { defineComponent, onMounted, ref, watch } from 'vue';
import { useStore } from '@/plugins/vuex';
import { computed } from 'vue';
import { IRate } from '@/shared/model/rate.model';
import { useI18N } from '@/plugins/i18n';
import { ExperienceCollection } from '@/shared/model/experience-collection.model'; //need it when come from teamproposal
import SReadMore from '@/components/stemdo-components/s-read-more/SReadMore.vue';
import SLanguageIcon from '@/components/stemdo-components/s-language-icon/SLanguageIcon.vue';
import { StemdoerStatus } from '@/shared/model/enumerations/stemdoer-status.model';
import StemdoerAvailability from './StemdoerAvailability.vue';
import SShareLink from '@/components/stemdo-components/s-share-link/SShareLink.vue';
import SPopUp from '@/components/stemdo-components/s-pop-up/SPopUp.vue';
import SBoxStemdoerCompsAndCerts from '@/components/stemdo-components/s-box-stemdoer-comps-and-certs/SBoxStemdoerCompsAndCerts.vue';
import SBoxStemdoerExperience from '@/components/stemdo-components/s-box-stemdoer-experience/SBoxStemdoerExperience.vue';
import SBoxStemdoerEducation from '@/components/stemdo-components/s-box-stemdoer-education/SBoxStemdoerEducation.vue';

export default defineComponent({
  props: {
    stemdoer: {
      type: Object as () => IStemdoer,
      required: true
    },
    rates: {
      type: Array as () => IRate[],
      required: false
    },
    rate: {
      type: Number,
      required: false
    },
    onAddToCart: {
      type: Function,
      required: true
    }
  },
  components: {
    SAvatarStemdoer,
    SReadMore,
    SButton,
    SLanguageIcon,
    StemdoerAvailability,
    SPopUp,
    SBoxStemdoerCompsAndCerts,
    SBoxStemdoerExperience,
    SBoxStemdoerEducation,
    SShareLink
  },

  setup(props) {
    const store = useStore();
    const i18n = useI18N();
    const stemdoerMutable = ref<Stemdoer>(new Stemdoer(props.stemdoer));
    const descriptionArray = ref([]);
    const loading = ref(false);
    const cart = computed(() => store.getters['cartStore/cartItems']);
    const clientSelected = store.getters['cartStore/clientSelected'];
    const needHide = ref(true);
    const stemdoerisInCart = computed(() => {
      return cart.value.some(item => item.id === props.stemdoer['id']);
    });
    const sPopUpData = ref({
      open: false,
      text: ''
    });

    onMounted(() => {
      const urlParams = new URLSearchParams(window.location.search);
      needHide.value = !urlParams.has('h');
      stemdoerMutable.value.technologyScores.orderByScoreDesc();
    });

    watch(
      () => props.stemdoer,
      value => {
        sPopUpData.value.open = false;
        stemdoerMutable.value = new Stemdoer(value);
        stemdoerMutable.value.technologyScores.orderByScoreDesc();
      }
    );

    const handleAddToCartBtn = () => {
      const stemdoerWithAvailability = props.stemdoer as { availability: { available: boolean } };
      if (stemdoerWithAvailability.availability.available) {
        props.onAddToCart((props.stemdoer as IStemdoer).id);
      }
    };

    const getRate = (seniority: string) => {
      let rate = props.rate ?? 36;
      let ratesSource: any[] = [];
      if (props.rates && props.rates.length > 0) {
        ratesSource = props.rates;
      } else if (clientSelected && store.getters.account?.authorities.includes('ROLE_GESTOR')) {
        ratesSource = clientSelected.clientRate?.rates || [];
      }
      if (Array.isArray(ratesSource)) {
        ratesSource.forEach(item => {
          if (item.seniority.toLowerCase().replace(/[-_]/g, '') == seniority.toLowerCase().replace(/[-_]/g, '')) {
            rate = item.value;
          }
        });
      }
      return rate;
    };

    const openPopUp = (open: boolean, text: string) => {
      sPopUpData.value.open = open;
      sPopUpData.value.text = text;
    };

    const getLanguageBadges = languages => {
      return languages.map(language => {
        const translationKey = 'explore.stemdoer-detail.language.' + language.name.toLowerCase();
        const translatedName = i18n.t(translationKey) as string;
        return {
          name: translatedName !== translationKey ? translatedName : language.name,
          level: language.level
        };
      });
    };

    return {
      stemdoerMutable,
      descriptionArray,
      loading,
      stemdoerisInCart,
      needHide,
      StemdoerStatus,
      sPopUpData,
      openPopUp,
      getRate,
      handleAddToCartBtn,
      getLanguageBadges
    };
  }
});
