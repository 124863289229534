import { defineComponent } from 'vue';
import { useI18N } from '@/plugins/i18n';
import { Stemdoer } from '@/shared/model/stemdoer.model';

export default defineComponent({
  props: {
    stemdoer: {
      type: Object as () => Stemdoer,
      required: true
    }
  },

  setup() {
    const i18n = useI18N();

    const getStarSkills = (skills: number) => {
      const skillLevel = skills;
      if (skillLevel <= 3) {
        return (
          '<img src="/content/svgs/star/star-fill-grey.svg" />' +
          '<img src="/content/svgs/star/star-empty-grey.svg" />' +
          '<img src="/content/svgs/star/star-empty-grey.svg" />'
        );
      } else if (skillLevel <= 5) {
        return (
          '<img src="/content/svgs/star/star-fill-grey.svg" />' +
          '<img src="/content/svgs/star/star-fill-grey.svg" />' +
          '<img src="/content/svgs/star/star-empty-grey.svg" />'
        );
      } else if (skillLevel >= 6) {
        return (
          '<img src="/content/svgs/star/star-fill-grey.svg" />' +
          '<img src="/content/svgs/star/star-fill-grey.svg" />' +
          '<img src="/content/svgs/star/star-fill-grey.svg" />'
        );
      }
    };

    const getTitleBadge = (skills: number) => {
      if (skills <= 3) {
        return i18n.t('technologyMaster.tech-badges.tier.1');
      } else if (skills <= 5) {
        return i18n.t('technologyMaster.tech-badges.tier.2');
      } else if (skills >= 6) {
        return i18n.t('technologyMaster.tech-badges.tier.3');
      }
    };

    const getStarsTooltip = (skills: number) => {
      let tooltip = 0;
      if (skills <= 3) {
        return (tooltip = 1);
      } else if (skills <= 5) {
        return (tooltip = 2);
      } else if (skills >= 6) {
        return (tooltip = 3);
      }
    };

    const getInfoBadge = (skills: number, id: number) => {
      let translationKey;
      if (skills <= 3) {
        translationKey = 'technologyMaster.tech-badges.' + id + '.1';
      } else if (skills <= 5) {
        translationKey = 'technologyMaster.tech-badges.' + id + '.2';
      } else if (skills >= 6) {
        translationKey = 'technologyMaster.tech-badges.' + id + '.3';
      }
      let translation = i18n.t(translationKey);
      if (translation === translationKey) {
        translation = i18n.t(translationKey.replace(id.toString(), '0'));
      }
      return translation;
    };

    return { getStarSkills, getTitleBadge, getStarsTooltip, getInfoBadge };
  }
});
