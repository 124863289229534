var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "SHeaderSection",
    {
      attrs: {
        title: _vm.$t("checkout.title"),
        onBackButtonClick: _vm.goToExplore,
      },
    },
    [
      _c(
        "template",
        { slot: "action" },
        [
          _c(
            "v-col",
            { staticClass: "d-flex align-center justify-end; col col-8" },
            [
              _c(
                "div",
                { staticClass: "toolBoxTop" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btnPDF",
                      staticStyle: {
                        "background-color": "rgba(255, 255, 255, 0) !important",
                      },
                      attrs: {
                        loading: _vm.pdfLoading,
                        disabled: _vm.pdfLoading,
                        fab: "",
                        "data-cy": "checkout-pdf-btn",
                      },
                      on: {
                        click: function ($event) {
                          _vm.pdfLoading = true
                          _vm.downloadPDF()
                        },
                      },
                    },
                    [
                      _c("v-img", {
                        staticClass: "iconPDF",
                        attrs: {
                          src: "/content/svgs/header/external-link.svg",
                          "data-cy": "checkout-pdf-icon",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "ml-1 text-body-1-bold",
                      attrs: { "data-cy": "checkout-pdf-text" },
                    },
                    [_vm._v("PDF")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "px-0 pt-10 pb-0 mx-0", style: { minWidth: "100%" } },
        [
          _c(
            "v-row",
            { staticClass: "mb-6", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "pa-0" },
                _vm._l(
                  _vm.teamRequest?.stemdoerRates,
                  function (stemdoerRates) {
                    return _c(
                      "v-row",
                      {
                        key: stemdoerRates?.stemdoer?.id,
                        attrs: { "no-gutters": "" },
                      },
                      [
                        _c(
                          "v-col",
                          { staticClass: "pa-0 mb-4" },
                          [
                            _c("SCardStemdoer", {
                              attrs: {
                                stemdoer: stemdoerRates.stemdoer,
                                rate: stemdoerRates.rate,
                                cardOptions: _vm.stemdoerCardOptions,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }
                ),
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "pa-0 ml-6 mb-4", attrs: { cols: "5" } },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("CheckoutOverview", {
                            attrs: {
                              hourlyCost: _vm.hourlyCost,
                              numStemdoers:
                                _vm.teamRequest.stemdoerRates.length,
                              client: _vm.clientSelected,
                              isUserClient: _vm.isUserClient,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "mt-6", attrs: { "no-gutters": "" } },
                    [
                      _c("v-col", [
                        _c(
                          "div",
                          [
                            _c("ButtonsGroup", {
                              attrs: {
                                teamRequest: _vm.teamRequest,
                                teamRequestStatus:
                                  _vm.extendedTeamRequestStatus,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.stemdoerList.length > 0
                    ? _c(
                        "v-row",
                        { staticClass: "mt-6", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "column",
                                "justify-content": "center",
                                "align-items": "center",
                              },
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { id: "checkout-chart" } },
                                [
                                  _c("SRadarStemdoersTechs", {
                                    attrs: { stemdoerList: _vm.stemdoerList },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: { display: "flex" },
                                  attrs: { id: "checkout-footprint" },
                                },
                                [
                                  _c("SFootprint", {
                                    attrs: { stemdoers: _vm.stemdoerList },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("ModalStemdoerDetail", {
        attrs: {
          isOpen: _vm.isStemdoerDetailModalOpen,
          onClose: _vm.closeStemdoerDetailModal,
          stemdoer: _vm.selectedStemdoer,
          rate: _vm.selectedStemdoerRate,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }