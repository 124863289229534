<template>
  <div class="pa-6" style="min-width: 282px; background-color: var(--v-background-darken1); border-radius: 4px">
    <div v-if="stemdoer.stemdoId" class="image-container mb-6">
      <SAvatarStemdoer :stemdoId="stemdoer.stemdoId" size="75px" />
    </div>
    <div class="d-flex flex-column align-items-center mb-8">
      <span class="text-body-1-bold text--text text--base mb-2"> {{ stemdoer.name + ' ' + stemdoer.surname }} </span>
      <span class="text-body-1-bold text--text text--base"> {{ stemdoer.position }} </span>
    </div>

    <div class="d-flex flex-column">
      <div class="d-flex flex-column mb-4">
        <span class="text-body-2-bold text--text text--lighten-1 mb-2" v-text="$t('stemdoerDetail.email')"> </span>
        <span class="text-body-1 text--text text--base"> {{ stemdoer.email }} </span>
      </div>
      <div class="d-flex flex-column mb-4">
        <span class="text-body-2-bold text--text text--lighten-1 mb-2" v-text="$t('stemdoerDetail.hireDate')"> </span>
        <span class="text-body-1 text--text text--base"> {{ $d(new Date(stemdoer.hireDate), 'twoDigit') }} </span>
      </div>
      <div v-if="stemdoer.languages && stemdoer.languages.length > 0" class="d-flex flex-column mb-4">
        <span class="text-body-2-bold text--text text--lighten-1 mb-2" v-text="$t('stemdoerDetail.languages')"> </span>
        <div v-for="language in getLanguageBadges(stemdoer.languages)" :key="language.name" class="d-flex flex-column mb-2">
          <span class="text-body-1 text--text text--base"> {{ language.name }} {{ language.level }} </span>
        </div>
      </div>
      <div v-if="stemdoer.mbti && stemdoer.mbti != ''" class="d-flex flex-column mb-4">
        <span class="text-body-2-bold text--text text--lighten-1 mb-2" v-text="$t('stemdoerDetail.mbti')"> </span>
        <span class="text-body-1 text--text text--base"> {{ stemdoer.mbti }} </span>
      </div>
      <div v-if="platformSeniority != null" class="d-flex flex-column">
        <span class="text-body-2-bold text--text text--lighten-1 mb-2" v-text="$t('stemdoerDetail.seniority')"> </span>
        <span class="text-body-1 text--text text--base"> {{ platformSeniority }} </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Stemdoer } from '@/shared/model/stemdoer.model';
import { computed, defineComponent } from 'vue';
import { useI18N } from '@/plugins/i18n';
import SAvatarStemdoer from '@/components/stemdo-components/s-avatar-stemdoer/SAvatarStemdoer.vue';

export default defineComponent({
  props: {
    stemdoer: {
      type: Object as () => Stemdoer,
      required: true
    }
  },
  components: {
    SAvatarStemdoer
  },
  setup(props) {
    const i18n = useI18N();

    const platformSeniority = computed(() => {
      if (props.stemdoer.seniority === 'JUNIOR') {
        return i18n.t('experienceLevel.explorer').toString();
      } else if (props.stemdoer.seniority === 'MID_SENIOR') {
        return i18n.t('experienceLevel.strategist').toString();
      } else if (props.stemdoer.seniority === 'SENIOR') {
        return i18n.t('experienceLevel.visionary').toString();
      } else {
        return null;
      }
    });

    const getLanguageBadges = languages => {
      return languages.map(language => {
        const translationKey = 'explore.stemdoer-detail.language.' + language.name.toLowerCase();
        const translatedName = i18n.t(translationKey) as string;
        return {
          name: translatedName !== translationKey ? translatedName : language.name,
          level: language.level
        };
      });
    };

    return { platformSeniority, getLanguageBadges };
  }
});
</script>

<style lang="scss" scoped>
.image-container {
  display: flex;
  justify-content: center;
  margin-top: -60px;
}

.image-container > * {
  z-index: 1; // Asegura que la imagen esté por encima del fondo gris
}
</style>
