var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "bg-white",
      attrs: { id: "stemdoerDetail", "data-cy": "stemdoer-detail-container" },
    },
    [
      _c("SPopUp", {
        attrs: {
          "data-cy": "stemdoer-detail-popup",
          isOpen: _vm.sPopUpData.open,
          sText: _vm.sPopUpData.text,
          isValid: true,
        },
        on: {
          close: () => {
            _vm.sPopUpData.open = false
          },
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pa-8", attrs: { "data-cy": "stemdoer-detail-main" } },
        [
          _c(
            "div",
            {
              staticClass: "d-flex",
              attrs: { "data-cy": "stemdoer-detail-header" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex justify-center",
                  staticStyle: { "flex-direction": "column" },
                  attrs: { "data-cy": "stemdoer-avatar-section" },
                },
                [
                  _vm.needHide
                    ? _c("SAvatarStemdoer", {
                        staticStyle: { "z-index": "1" },
                        attrs: {
                          "data-cy": "stemdoer-avatar",
                          stemdoId: _vm.stemdoerMutable.stemdoId,
                          size: "120px",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "d-flex flex-column ml-6 py-2",
                  attrs: { "data-cy": "stemdoer-info-section" },
                },
                [
                  _c("div", { attrs: { "data-cy": "stemdoer-name-row" } }, [
                    _vm.needHide
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "text-body-1-bold text--text text--base",
                            attrs: { "data-cy": "stemdoer-name" },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.stemdoerMutable.name) +
                                " " +
                                _vm._s(
                                  _vm.stemdoerMutable.surname.split("")[0]
                                ) +
                                ".\n          "
                            ),
                          ]
                        )
                      : _c(
                          "span",
                          {
                            staticClass:
                              "text-body-1-bold text--text text--base",
                            attrs: { "data-cy": "stemdoer-anonymous-name" },
                          },
                          [
                            _vm._v(
                              "\n            Stemdoer " +
                                _vm._s(_vm.stemdoerMutable.stemdoId) +
                                "\n          "
                            ),
                          ]
                        ),
                  ]),
                  _vm._v(" "),
                  _c("div", { attrs: { "data-cy": "stemdoer-position-row" } }, [
                    _c(
                      "span",
                      {
                        staticClass: "text-h2-bold text--text text--base",
                        attrs: { "data-cy": "stemdoer-position" },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.stemdoerMutable.position) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex align-start pt-2 h-100",
                      attrs: { "data-cy": "stemdoer-badges-row" },
                    },
                    [
                      _vm.stemdoerMutable.office
                        ? _c(
                            "div",
                            {
                              staticClass: "badge-style",
                              attrs: { "data-cy": "stemdoer-office-badge" },
                            },
                            [
                              _c("div", [
                                _c("span", {
                                  staticClass: "badge-text text-subtitle-3",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("explore.stemdoer-detail.office")
                                    ),
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "badge-text text-subtitle-4" },
                                  [_vm._v(_vm._s(_vm.stemdoerMutable.office))]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(
                        _vm.getLanguageBadges(_vm.stemdoerMutable.languages),
                        function (language, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "badge-style",
                              style: { marginLeft: "8px" },
                              attrs: {
                                "data-cy": "stemdoer-language-badge-" + index,
                              },
                            },
                            [
                              _c("div", [
                                _c(
                                  "span",
                                  { staticClass: "badge-text text-subtitle-3" },
                                  [_vm._v(_vm._s(language.name) + ":")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "badge-text text-subtitle-4" },
                                  [_vm._v(_vm._s(language.level))]
                                ),
                              ]),
                            ]
                          )
                        }
                      ),
                      _vm._v(" "),
                      _vm.stemdoerMutable.mbti
                        ? _c(
                            "div",
                            {
                              staticClass: "badge-style",
                              style: { marginLeft: "8px" },
                              attrs: { "data-cy": "stemdoer-mbti-badge" },
                            },
                            [
                              _c("div", [
                                _c("span", {
                                  staticClass: "badge-text text-subtitle-3",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("explore.stemdoer-detail.mbti")
                                    ),
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "badge-text text-subtitle-4" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticStyle: {
                                          color: "inherit",
                                          "text-decoration": "none",
                                        },
                                        attrs: {
                                          target: "_blank",
                                          href:
                                            "https://www.16personalities.com/" +
                                            _vm.stemdoerMutable.mbti.toLowerCase() +
                                            "-personality",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.stemdoerMutable.mbti) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "d-flex flex-column ml-auto py-2",
                  attrs: { "data-cy": "stemdoer-actions-column" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex justify-end",
                      attrs: { "data-cy": "stemdoer-share-row" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "toolBoxTop" },
                        [
                          _c("SShareLink", {
                            staticClass: "my-4",
                            attrs: {
                              "data-cy": "stemdoer-share-link",
                              type: "stemdoer",
                              id: _vm.stemdoerMutable.id,
                              onLinkCopied: _vm.openPopUp,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.stemdoerisInCart &&
                  _vm.stemdoerMutable.availability.available &&
                  _vm.$route.name !== "teamRequestByClientId" &&
                  _vm.needHide
                    ? _c(
                        "div",
                        {
                          staticClass: "d-flex justify-end mt-auto",
                          attrs: { "data-cy": "stemdoer-add-to-cart-row" },
                        },
                        [
                          _c("SButton", {
                            staticStyle: {
                              "max-width": "175px !important",
                              width: "175px !important",
                            },
                            attrs: {
                              "data-cy": "stemdoer-add-to-cart-btn",
                              btnStyle: "primary",
                              sText: _vm.$t("explore.stemdoer-detail.addBtn"),
                              action: _vm.handleAddToCartBtn,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex mt-1",
                      attrs: { "data-cy": "stemdoer-rate-row" },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "text-body-1 text--text text--lighten-1 mb-0",
                        },
                        [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("explore.stemdoer-detail.rate")
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "text-body-1-medium primary--text text--base",
                              staticStyle: {
                                "text-align": "start",
                                width: "100% !important",
                              },
                              attrs: { "data-cy": "stemdoer-hourly-rate" },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.getRate(_vm.stemdoerMutable.seniority)
                                  ) +
                                  " €/h\n            "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "mt-5 pa-4",
              staticStyle: {
                width: "100%",
                height: "100%",
                background: "#f9f9f9",
                "border-radius": "4px",
              },
              attrs: { "data-cy": "stemdoer-availability-section" },
            },
            [
              _c("StemdoerAvailability", {
                attrs: {
                  stemdoer: _vm.stemdoerMutable,
                  "data-cy": "stemdoer-availability-component",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt-5" },
            [
              _c("SBoxStemdoerCompsAndCerts", {
                attrs: { stemdoer: _vm.stemdoerMutable },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt-8" },
            [
              _c("SBoxStemdoerExperience", {
                attrs: { stemdoer: _vm.stemdoerMutable },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt-8" },
            [
              _c("SBoxStemdoerEducation", {
                attrs: { stemdoer: _vm.stemdoerMutable },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }