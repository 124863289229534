import { defineComponent, ref, computed, watch, onMounted, inject } from 'vue';
import SHeaderSection from '@/components/stemdo-components/s-header-section/SHeaderSection.vue';
import SButtonBack from '@/components/stemdo-components/s-button-back/SButtonBack.vue';
import { IStemdoer, Stemdoers } from '@/shared/model/stemdoer.model';
import CheckoutOverview from './checkout-overview/CheckoutOverview.vue';
import { useStore } from '@/plugins/vuex';
import { useRouter } from '@/plugins/router';
import { CardOptions } from '@/components/stemdo-components/s-card-stemdoer/sCardStemdoer';
import { TeamRequest } from '@/shared/model/team-request.model';
import SModalTeamRequestConfirmation from '@/components/stemdo-components/s-modal-team-request-confirmation/SModalTeamRequestConfirmation.vue';
import ButtonsGroup from './buttons-group/ButtonsGroup.vue';
import { ExtendedTeamRequestStatus } from './enum/extendedTeamRequestStatus';
import { RequestStatus } from '@/shared/model/enumerations/request-status.model';
import TeamRequestService from '@/sections/admin/entities/team-request/team-request.service';
import ClientRateService from '@/sections/admin/entities/client-rate/client-rate.service';
import PdfService from '@/sections/admin/entities/pdf/pdf.service';
import ClientService from '@/sections/admin/entities/client/client.service';
import ModalStemdoerDetail from '../stemdoer-detail/ModalStemdoerDetail.vue';
import SRadarStemdoersTechs from '@/components/stemdo-components/s-radar-stemdoers-techs/SRadarStemdoersTechs.vue';
import SFootprint from '@/components/stemdo-components/s-footprint/SFootprint.vue';

export default defineComponent({
  props: {
    teamRequestId: {
      type: String
    }
  },
  components: {
    SButtonBack,
    CheckoutOverview,
    SHeaderSection,
    SModalTeamRequestConfirmation,
    ButtonsGroup,
    SRadarStemdoersTechs,
    SFootprint,
    ModalStemdoerDetail
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const teamRequestService: TeamRequestService = inject('teamRequestService');
    const pdfService: PdfService = inject('pdfService');
    const clientRateService: ClientRateService = inject('clientRateService');
    const clientService = inject('clientService') as () => ClientService;
    const isUserManager = computed(() => store.getters.account?.authorities.includes('ROLE_GESTOR') ?? false);
    const isUserClient = computed(() => store.getters.account?.authorities.includes('ROLE_CLIENT') ?? false);
    const teamRequest = ref<TeamRequest>(TeamRequest.empty());
    const clientSelected = computed(() => store.getters['cartStore/clientSelected']);
    const hourlyCost = ref<number>(0);
    const isStemdoerDetailModalOpen = ref(false);
    const selectedStemdoer = ref<IStemdoer>({});
    const selectedStemdoerRate = ref<number>(0);
    const stemdoerList = ref<IStemdoer[]>([]);
    const pdfLoading = ref(false);
    const extendedTeamRequestStatus = computed(() => {
      if (isUserManager.value) {
        if (!props.teamRequestId) {
          return ExtendedTeamRequestStatus.MANAGER_AND_CHECKOUT;
        }
        if (
          props.teamRequestId &&
          (teamRequest.value.status === RequestStatus.PENDING || teamRequest.value.status === RequestStatus.VALIDATED)
        ) {
          return ExtendedTeamRequestStatus.MANAGER_AND_NEW_PROPOSAL;
        }
      }
      if (isUserClient.value) {
        if (props.teamRequestId) {
          if (teamRequest.value.status === RequestStatus.VALIDATED) {
            return ExtendedTeamRequestStatus.CLIENT_AND_VALIDATED;
          }
          if (teamRequest.value.status === RequestStatus.SAVED) {
            return ExtendedTeamRequestStatus.CLIENT_AND_SAVED;
          }
        } else {
          return ExtendedTeamRequestStatus.CLIENT_AND_CHECKOUT;
        }
      }
      return undefined;
    });

    onMounted(async () => {
      if (props.teamRequestId) {
        teamRequest.value = await retrieveTeamRequest(props.teamRequestId);
        teamRequest.value.removeNotAvailablesStemdoer();
      }

      if (isUserClient.value) {
        const clientId = store.getters['clientAccount'].id;
        clientService()
          .find(clientId)
          .then(res => {
            store.commit('cartStore/setClient', res);
          });
      }

      const stemdoersInCart: Stemdoers = Stemdoers.from(store.getters['cartStore/cartItems']);

      const clientRates = clientSelected.value.clientRate?.rates || [];
      stemdoersInCart.setRates(clientRates);
      if (!props.teamRequestId) teamRequest.value.client = { id: clientSelected.value.id, email: clientSelected.value.email };

      teamRequest.value.addStemdoerRates(stemdoersInCart);
      if (teamRequest.value.status === RequestStatus.SAVED) {
        const clientRates = await clientRateService.retrieveClientRates();
        teamRequest.value.stemdoerRates.setRates(clientRates);
      }
      if (isUserClient.value) {
        const clientRates = await clientRateService.retrieveClientRates();
        teamRequest.value.stemdoerRates.setRates(clientRates);
      }

      redirectIfNoStemdoers();
    });

    watch(
      () => teamRequest.value,
      _ => {
        redirectIfNoStemdoers();
        stemdoerList.value = teamRequest.value.stemdoerRates.map(stemdoerRate => stemdoerRate.stemdoer);
        hourlyCost.value = teamRequest.value.calculateTotalRate();
      },
      { deep: true }
    );

    const redirectIfNoStemdoers = () => {
      if (isUserManager.value === true) {
        if (teamRequest.value.stemdoerRates.length <= 0) {
          router.push({ name: 'exploreManager' });
        }
      }
      if (isUserClient.value === true) {
        if (teamRequest.value.stemdoerRates.length <= 0) {
          router.push({ name: 'exploreClient' });
        }
      }
    };

    const retrieveTeamRequest = async (id: string): Promise<TeamRequest> => {
      try {
        const tr = await teamRequestService.find(id);
        return new TeamRequest(tr);
      } catch (error) {
        console.error(error);
      }
    };

    const stemdoerCardOptions: CardOptions = {
      isRateEditable: isUserManager.value,
      isDeletable: true,
      isClickable: true,
      isRateVisible: isUserClient.value,
      onRateChange: (stemdoerId: string, newRate: number) => {
        teamRequest.value.updateRate(stemdoerId, newRate);
      },
      onDelete: id => {
        try {
          store.commit('cartStore/removeStemdoer', id);
          teamRequest.value.removeStemdoerById(id);
        } catch (error) {
          console.error(`Error deleting Stemdoer with id: ${id}`, error);
        }
      },
      onClick: (stemdoerId: string) => {
        const stemdoerRate = teamRequest.value.stemdoerRates.findByStemdoerId(stemdoerId);
        selectedStemdoer.value = stemdoerRate.stemdoer;
        selectedStemdoerRate.value = stemdoerRate.rate;
        isStemdoerDetailModalOpen.value = true;
      }
    };

    const closeStemdoerDetailModal = () => {
      isStemdoerDetailModalOpen.value = false;
    };

    const goToExplore = async () => {
      try {
        //tODO: check if trid exist or manager or client
        if (!props.teamRequestId) await router.back();
        if (isUserManager.value === true) {
          await router.push({ name: 'exploreManager', query: { tr: props.teamRequestId, 'not-load': 'true' } });
        }
        if (isUserClient.value === true) {
          await router.push({ name: 'exploreClient', query: { tr: props.teamRequestId, 'not-load': 'true' } });
        }
      } catch (error) {
        console.error('Error al navegar hacia atrás:', error);
      }
    };

    const downloadPDF = () => {
      pdfLoading.value = true;
      const stemdoerIds = teamRequest.value.stemdoerRates.map(sr => sr.stemdoer.id);
      if (isUserManager.value) {
        const clientEmail: string = clientSelected.value.email;
        pdfService.downloadTeamRequestPdfFromStemdoers(stemdoerIds, clientEmail).then(blobPdf => {
          pdfService.handlePdfDownload(`team-request.pdf`, blobPdf);
          pdfLoading.value = false;
        });
      } else {
        pdfService.downloadTeamRequestPdfFromStemdoers(stemdoerIds).then(blobPdf => {
          pdfService.handlePdfDownload(`team-request.pdf`, blobPdf);
          pdfLoading.value = false;
        });
      }
    };

    return {
      isUserClient,
      teamRequest,
      extendedTeamRequestStatus,
      clientSelected,
      hourlyCost,
      stemdoerCardOptions,
      isStemdoerDetailModalOpen,
      selectedStemdoer,
      stemdoerList,
      selectedStemdoerRate,
      pdfLoading,
      closeStemdoerDetailModal,
      goToExplore,
      downloadPDF
    };
  }
});
