var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "w-100 d-flex flex-column flex-fill" },
    [
      _vm.label
        ? _c("span", {
            staticClass: "text-body-2-bold text--text text--lighten-1 mb-2",
            domProps: { textContent: _vm._s(_vm.label) },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("v-select", {
        staticClass: "custom-v-selector",
        attrs: {
          "data-cy": "sselector",
          label: _vm.placeholder,
          items: _vm.items,
          "item-value": "id",
          "item-text": "desc",
          "return-object": "",
          dense: "",
          flat: "",
          solo: "",
          "menu-props": { offsetY: true },
        },
        on: { change: _vm.handleChange },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function ({}) {
              return [
                _c(
                  "span",
                  {
                    staticClass: "pt-1 pb-1 text-body-1 text--text",
                    attrs: { "data-cy": "sselector-selection-label" },
                  },
                  [_vm._v("\n        " + _vm._s(_vm.labelDisplay) + "\n      ")]
                ),
              ]
            },
          },
          {
            key: "item",
            fn: function ({ item }) {
              return [
                _c(
                  "div",
                  {
                    class: [
                      "text--text",
                      "text--base",
                      { "selected-item": _vm.isItemSelected(item) },
                      { "text-body-1-bold": _vm.isItemSelected(item) },
                      { "text-body-1": !_vm.isItemSelected(item) },
                    ],
                    attrs: { "data-cy": "sselector-item" },
                  },
                  [_vm._v("\n        " + _vm._s(item.desc) + "\n      ")]
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.selectedItem,
          callback: function ($$v) {
            _vm.selectedItem = $$v
          },
          expression: "selectedItem",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }