import { defineComponent, PropType, ref, watch } from 'vue';

export default defineComponent({
  props: {
    value: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: true
    },
    errorMessage: {
      type: String,
      required: false
    }
  },
  setup(props, { emit }) {
    const date = ref(props.value || undefined);
    const isDatePickerOpen = ref(false);

    watch(
      () => props.value,
      val => {
        date.value = val;
      }
    );

    const onDateChange = (newValue: string) => {
      emit('input', newValue);
      closeDatePicker();
    };

    const closeDatePicker = () => {
      isDatePickerOpen.value = false;
    };

    return { isDatePickerOpen, date, onDateChange };
  }
});
