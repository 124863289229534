import { defineComponent } from 'vue';
import { IClient } from '@/shared/model/client.model';

export default defineComponent({
  props: {
    hourlyCost: {
      type: Number,
      required: true
    },
    numStemdoers: {
      type: Number,
      required: true
    },
    client: {
      type: Object as () => IClient,
      required: false
    },
    isUserClient: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    return {};
  }
});
