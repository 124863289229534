<template>
  <StemdoerDetails :stemdoerId="stemdoerId" />
</template>

<script setup>
import { useRouter } from '@/plugins/router';
import StemdoerDetails from '@/sections/manager/stemdoer-detail/StemdoerDetail.vue';
const router = useRouter();
const stemdoerId = router.currentRoute.params['id'].toString();
</script>
