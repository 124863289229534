import { defineComponent, inject, ref } from 'vue';
import { useStore } from '@/plugins/vuex';
import { useRouter } from '@/plugins/router';
import SButton from '@/components/stemdo-components/s-button/SButton.vue';
import SModalTeamRequestBlock from '@/components/stemdo-components/s-modal-team-request-confirmation/SModalTeamRequestConfirmation.vue';
import SModalTeamRequestSave from '@/components/stemdo-components/s-modal-team-request-confirmation/SModalTeamRequestConfirmation.vue';
import TeamRequestService from '@/sections/admin/entities/team-request/team-request.service';
import { TeamRequest } from '@/shared/model/team-request.model';
import { RequestStatus } from '@/shared/model/enumerations/request-status.model';
import { useI18N } from '@/plugins/i18n';

export default defineComponent({
  props: {
    teamRequest: {
      type: Object as () => TeamRequest,
      required: true
    }
  },
  components: {
    SButton,
    SModalTeamRequestBlock,
    SModalTeamRequestSave
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const i18n = useI18N();
    const teamRequestService: TeamRequestService = inject('teamRequestService');
    const isTeamRequestModalOpen = ref(false);
    const isTeamRequestSaveModalOpen = ref(false);
    const isTeamRequestConfirmationModalOpen = ref(false);

    const requestType = ref('');
    const customModalProps = { bodyPaddingY: 0, fullWidthFooter: true };
    const client = localStorage.getItem('clientSelected');

    const customPropsModal = {
      title: i18n.t('sModalTeamRequestConfirmation.saveTitle'),
      primaryButtonText: i18n.t('sModalTeamRequestConfirmation.label.saveBtn'),
      initDateRequired: requestType.value === 'request' ? true : false
    };

    const openTeamRequestBlockModal = () => {
      requestType.value = 'request';
      isTeamRequestModalOpen.value = true;
    };

    const closeTeamRequestConfirmationModal = () => {
      isTeamRequestModalOpen.value = false;
    };

    const openTeamRequestSaveModal = () => {
      requestType.value = 'save';
      isTeamRequestSaveModalOpen.value = true;
    };

    const closeTeamRequestSaveModal = () => {
      isTeamRequestSaveModalOpen.value = false;
    };

    const gotoExplore = () => {
      requestType.value = '';
      router.push({
        name: 'exploreClient'
      });
    };

    const saveTeamRequest = (teamRequest: TeamRequest) => {
      teamRequest.status = RequestStatus.SAVED;
      store.commit('cartStore/setClient', client);
      teamRequestService
        .create(teamRequest)
        .then(_ => {
          store.commit('cartStore/clearCart');
        })
        .catch(error => {
          console.error(error);
        });
    };

    const requestTeamRequest = (teamRequest: TeamRequest) => {
      teamRequest.status = RequestStatus.PENDING;
      store.commit('cartStore/setClient', client);
      teamRequestService
        .create(teamRequest)
        .then(_ => {
          store.commit('cartStore/clearCart');
        })
        .catch(error => {
          console.error(error);
        });
    };

    const onClickTeamRequestConfirmationModal = (teamRequestName: string, initDate: string, endDate: string, jobDescription: string) => {
      const mutableTeamRequest = { ...props.teamRequest };
      mutableTeamRequest.name = teamRequestName;
      mutableTeamRequest.initDate = new Date(initDate);
      mutableTeamRequest.endDate = endDate ? new Date(endDate) : null;
      mutableTeamRequest.jobDescription = jobDescription;
      if (requestType.value === 'save') {
        saveTeamRequest(mutableTeamRequest);
      } else if (requestType.value === 'request') {
        requestTeamRequest(mutableTeamRequest);
      }
      isTeamRequestConfirmationModalOpen.value = true;
    };

    return {
      isTeamRequestConfirmationModalOpen,
      isTeamRequestSaveModalOpen,

      openTeamRequestSaveModal,
      closeTeamRequestSaveModal,
      openTeamRequestBlockModal,
      closeTeamRequestConfirmationModal,
      onClickTeamRequestConfirmationModal,
      isTeamRequestModalOpen,
      gotoExplore,
      customPropsModal,
      i18n,
      requestType,
      customModalProps
    };
  }
});
