const forbiddenDomains = [
  '@stemdo',
  '@gmail',
  '@yahoo',
  '@hotmail',
  '@outlook',
  '@live',
  '@aol',
  '@icloud',
  '@mail',
  '@zoho',
  '@yandex',
  '@protonmail',
  '@gmx',
  '@me',
  '@msn',
  '@rocketmail',
  '@inbox',
  '@fastmail',
  '@rediffmail',
  '@tutanota',
  '@hushmail',
  '@lycos',
  '@mac',
  '@comcast',
  '@att',
  '@verizon',
  '@btinternet',
  '@ntlworld',
  '@virginmedia',
  '@sbcglobal',
  '@earthlink',
  '@optonline',
  '@cox',
  '@shaw',
  '@sympatico',
  '@telus',
  '@qq',
  '@163',
  '@126',
  '@sina',
  '@yeah'
];

export default forbiddenDomains;
