var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.teamRequestId
    ? _c(_setup.Checkout, { attrs: { teamRequestId: _setup.teamRequestId } })
    : _c(_setup.Checkout)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }