import axios from 'axios';

const baseApiUrl = 'api/pdf';

export default class PdfService {
  public downloadTeamRequestPdf(teamRequestId: string): Promise<Blob> {
    return new Promise<Blob>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/team-requests/${teamRequestId}`, { responseType: 'blob' })
        .then((res: { data: Blob }) => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public downloadTeamRequestPdfFromStemdoers(stemdoerIds: string[], clientEmail?: string): Promise<Blob> {
    return new Promise<Blob>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/team-requests`, stemdoerIds, { params: { clientEmail }, responseType: 'blob' })
        .then((res: { data: Blob }) => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public downloadTeamRequestPdfFromTeamRequest(teamRequestId: string, h?: boolean): Promise<Blob> {
    return new Promise<Blob>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/team-requests/${teamRequestId}`, { params: { h }, responseType: 'blob' })
        .then((res: { data: Blob }) => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public downloadStemdoerPdf(stemdoerId: string, h?: boolean): Promise<Blob> {
    return new Promise<Blob>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/stemdoer/${stemdoerId}`, { params: { h }, responseType: 'blob' })
        .then((res: { data: Blob }) => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public handlePdfDownload(fileName: string, data: Blob): void {
    const url = URL.createObjectURL(data);
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.setAttribute('download', fileName);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(url);
  }
}
