import { reactive, computed } from 'vue';
import { required } from '@vuelidate/validators';

export const experienceForm = reactive({
  position: '',
  company: '',
  startDate: '',
  endDate: '',
  competencies: [],
  description: ''
});

export const resetExperienceForm = () => {
  experienceForm.position = '';
  experienceForm.company = '';
  experienceForm.startDate = '';
  experienceForm.endDate = '';
  experienceForm.competencies = [];
  experienceForm.description = '';
};

export const fillExperienceForm = (experience, competenciesItems) => {
  const competencies = experience.technologies.split(';');
  const competenciesIds = competencies.map(competency => {
    const competencyItem = competenciesItems.find(item => item.desc === competency);
    return competencyItem ? competencyItem.id : competency;
  });
  experienceForm.position = experience.title;
  experienceForm.company = experience.company;
  experienceForm.startDate = experience.initDate;
  experienceForm.endDate = experience.endDate;
  experienceForm.description = experience.description;
  experienceForm.competencies = competenciesIds;
};

export const experienceFormRules = computed(() => {
  return {
    position: { required },
    company: { required },
    startDate: { required },
    endDate: {},
    competencies: { required },
    description: { required }
  };
});
