var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { "data-cy": "stemdoer-experience-section" } }, [
    _c("div", { staticClass: "d-flex mb-4" }, [
      _c("span", {
        staticClass: "header-content",
        attrs: { "data-cy": "stemdoer-experience-title" },
        domProps: {
          innerHTML: _vm._s(_vm.$t("sBoxStemdoerExperience.experience")),
        },
      }),
      _vm._v(" "),
      _vm.onAddExperienceClick
        ? _c(
            "div",
            {
              staticClass: "d-flex ml-2",
              staticStyle: { cursor: "pointer" },
              on: {
                click: function ($event) {
                  return _vm.onAddExperienceClick(null)
                },
              },
            },
            [
              _c("img", {
                attrs: { width: "20px", src: "/content/svgs/add.svg" },
              }),
            ]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "main-box" },
      [
        _c(
          "v-col",
          { staticClass: "pl-3 pt-3", attrs: { cols: "12" } },
          [
            _c(
              "v-row",
              {
                staticClass: "mx-3 my-3",
                attrs: { "data-cy": "stemdoer-experience-list" },
              },
              _vm._l(
                _vm.stemdoerMutable.experience,
                function (experience, exIndex) {
                  return _c(
                    "div",
                    {
                      key: exIndex,
                      staticClass: "exp-container",
                      attrs: { "data-cy": "stemdoer-experience-item" },
                    },
                    [
                      experience &&
                      experience.experiences &&
                      experience.experiences.length > 1
                        ? _c(
                            "div",
                            {
                              staticClass: "ma-0",
                              attrs: {
                                "data-cy": "stemdoer-experience-multiple",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "pa-0" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "ma-0 pa-0 d-flex",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            bottom: "",
                                            "content-class":
                                              "background-darken3 tooltip-bottom",
                                            "nudge-bottom": "-26",
                                            "nudge-right": "6",
                                            "data-cy":
                                              "stemdoer-experience-tooltip",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "span",
                                                      _vm._g(
                                                        _vm._b(
                                                          {},
                                                          "span",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "v-sheet",
                                                          {
                                                            staticClass: "mr-2",
                                                            staticStyle: {
                                                              "border-radius":
                                                                "3px",
                                                              "font-size":
                                                                "17px",
                                                              padding:
                                                                "0.1em 0em 0em 0.2em",
                                                            },
                                                            attrs: {
                                                              color: "#f6f6f6",
                                                              elevation: "0",
                                                              width: "35px",
                                                              height: "35px",
                                                              "data-cy":
                                                                "stemdoer-experience-icon",
                                                            },
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                src: "/content/svgs/stemdoer-detail/experience.svg",
                                                                width: "30px",
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _vm.isStemdoExp(
                                                              experience.company
                                                            )
                                                              ? _c("img", {
                                                                  staticClass:
                                                                    "verfication-mark",
                                                                  attrs: {
                                                                    src: "/content/svgs/stemdo-verified.svg",
                                                                    alt: "",
                                                                    width:
                                                                      "20px",
                                                                  },
                                                                })
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              attrs: {
                                                "data-cy":
                                                  "stemdoer-experience-tooltip-text",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "sBoxStemdoerExperience.verify"
                                                    )
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "company pa-0",
                                            staticStyle: {
                                              "margin-top": "-0.4em",
                                            },
                                            attrs: {
                                              "data-cy":
                                                "stemdoer-experience-company-name",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(experience.company) +
                                                "\n                  "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "total-time pa-0 text-capitalize mb-7",
                                            staticStyle: {
                                              "margin-top": "-1.2em",
                                            },
                                            attrs: {
                                              "data-cy":
                                                "stemdoer-experience-company-total-time",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm
                                                    .getTotalTime(
                                                      experience.totalTime
                                                    )
                                                    .toString()
                                                ) +
                                                "\n                  "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              experience &&
                              experience.experiences &&
                              experience.experiences.length > 1
                                ? _c(
                                    "v-timeline",
                                    {
                                      attrs: {
                                        "data-cy":
                                          "stemdoer-experience-timeline",
                                        dense: "",
                                      },
                                    },
                                    _vm._l(
                                      experience.experiences,
                                      function (experienceCompany, cIndex) {
                                        return _c(
                                          "v-timeline-item",
                                          {
                                            key: cIndex,
                                            attrs: {
                                              color: "#DBDBE0",
                                              "fill-dot": true,
                                              density: "compact",
                                              small: "",
                                              "data-cy":
                                                "stemdoer-experience-timeline-item",
                                            },
                                          },
                                          [
                                            _c("div", [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "margin-top":
                                                      "-12px !important",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "title-position d-flex align-center",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            experienceCompany.title
                                                          ) +
                                                          "\n                      "
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-body-3 pl-1",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.sectorFormatted(
                                                                  experienceCompany.sector
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  experienceCompany.language &&
                                                  experienceCompany.language !=
                                                    "SPANISH"
                                                    ? _c("SLanguageIcon", {
                                                        attrs: {
                                                          language:
                                                            experienceCompany.language,
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.onEditExperienceClick
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex ml-2",
                                                          staticStyle: {
                                                            cursor: "pointer",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onEditExperienceClick(
                                                                experienceCompany
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              width: "20px",
                                                              src: "/content/svgs/edit.svg",
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "date",
                                                  attrs: {
                                                    "data-cy":
                                                      "stemdoer-experience-timeline-date",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm
                                                          .$d(
                                                            new Date(
                                                              experienceCompany.initDate
                                                            ),
                                                            "longWithoutDay"
                                                          )
                                                          .charAt(0)
                                                          .toUpperCase() +
                                                          _vm
                                                            .$d(
                                                              new Date(
                                                                experienceCompany.initDate
                                                              ),
                                                              "longWithoutDay"
                                                            )
                                                            .slice(1)
                                                      ) +
                                                      "\n                    -\n                    " +
                                                      _vm._s(
                                                        experienceCompany.endDate !==
                                                          null
                                                          ? _vm
                                                              .$d(
                                                                new Date(
                                                                  experienceCompany.endDate
                                                                ),
                                                                "longWithoutDay"
                                                              )
                                                              .charAt(0)
                                                              .toUpperCase() +
                                                              _vm
                                                                .$d(
                                                                  new Date(
                                                                    experienceCompany.endDate
                                                                  ),
                                                                  "longWithoutDay"
                                                                )
                                                                .slice(1)
                                                          : _vm.$t(
                                                              "sBoxStemdoerExperience.present"
                                                            )
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("br"),
                                              _vm._v(" "),
                                              _vm.getTech(
                                                experienceCompany.technologies
                                              ).length > 0
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "mt-3 d-flex flex-wrap",
                                                      staticStyle: {
                                                        "row-gap": "10px",
                                                      },
                                                      attrs: {
                                                        "data-cy":
                                                          "stemdoer-experience-timeline-techs",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.getTech(
                                                        experienceCompany.technologies
                                                      ),
                                                      function (tech, tIndex) {
                                                        return _c(
                                                          "div",
                                                          { key: tIndex },
                                                          [
                                                            _c(
                                                              "v-chip",
                                                              {
                                                                staticClass:
                                                                  "mr-1 chips",
                                                                attrs: {
                                                                  "data-cy":
                                                                    "stemdoer-experience-timeline-tech-chip",
                                                                  density:
                                                                    "compact",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                        " +
                                                                    _vm._s(
                                                                      tech
                                                                    ) +
                                                                    "\n                      "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                [
                                                  _c("br"),
                                                  _vm._v(" "),
                                                  _c("SReadMore", {
                                                    staticStyle: {
                                                      "margin-left": "-2px",
                                                    },
                                                    attrs: {
                                                      "data-cy":
                                                        "stemdoer-experience-timeline-description",
                                                      text: experienceCompany.description,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      experience &&
                      experience.experiences &&
                      experience.experiences.length == 1 &&
                      experience.experiences[0]
                        ? _c(
                            "div",
                            {
                              staticClass: "ma-0",
                              attrs: {
                                "data-cy": "stemdoer-experience-single",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "pa-0" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "ma-0 pa-0 d-flex",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "v-sheet",
                                        {
                                          staticClass: "mr-2",
                                          staticStyle: {
                                            "border-radius": "3px",
                                            "font-size": "17px",
                                            padding: "0.1em 0em 0em 0.2em",
                                          },
                                          attrs: {
                                            color: "#f6f6f6",
                                            elevation: "0",
                                            width: "35px",
                                            height: "35px",
                                            "data-cy":
                                              "stemdoer-experience-icon-single",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: "/content/svgs/stemdoer-detail/education.svg",
                                              width: "30px",
                                            },
                                          }),
                                          _vm._v(" "),
                                          experience &&
                                          experience.experiences &&
                                          _vm.isStemdoExp(
                                            experience.experiences[0].company
                                          )
                                            ? _c("img", {
                                                staticClass: "verfication-mark",
                                                attrs: {
                                                  src: "/content/svgs/stemdo-verified.svg",
                                                  alt: "",
                                                  width: "20px",
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "d-flex flex-column" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "d-flex" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "company pa-0 text-capitalize",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        experience.company
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              experience.experiences[0]
                                                .language &&
                                              experience.experiences[0]
                                                .language != "SPANISH"
                                                ? _c("SLanguageIcon", {
                                                    staticClass: "ml-1",
                                                    attrs: {
                                                      width: "20",
                                                      language:
                                                        experience
                                                          .experiences[0]
                                                          .language,
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.onEditExperienceClick
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex ml-1",
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.onEditExperienceClick(
                                                            experience
                                                              .experiences[0]
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          width: "20px",
                                                          src: "/content/svgs/edit.svg",
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "d-flex" }, [
                                            _c(
                                              "span",
                                              { staticClass: "position" },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      experience.experiences[0]
                                                        .title
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            experience.experiences[0].sector
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-body-3 pl-1",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          _vm.sectorFormatted(
                                                            experience
                                                              .experiences[0]
                                                              .sector
                                                          )
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "date ml-7",
                                  attrs: {
                                    "data-cy":
                                      "stemdoer-experience-single-date",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm
                                          .$d(
                                            new Date(
                                              experience.experiences[0].initDate
                                            ),
                                            "longWithoutDay"
                                          )
                                          .charAt(0)
                                          .toUpperCase() +
                                          _vm
                                            .$d(
                                              new Date(
                                                experience.experiences[0].initDate
                                              ),
                                              "longWithoutDay"
                                            )
                                            .slice(1)
                                      ) +
                                      "\n              -\n              " +
                                      _vm._s(
                                        experience.experiences[0].endDate !==
                                          null
                                          ? _vm
                                              .$d(
                                                new Date(
                                                  experience.experiences[0].endDate
                                                ),
                                                "longWithoutDay"
                                              )
                                              .charAt(0)
                                              .toUpperCase() +
                                              _vm
                                                .$d(
                                                  new Date(
                                                    experience.experiences[0].endDate
                                                  ),
                                                  "longWithoutDay"
                                                )
                                                .slice(1)
                                          : _vm.$t(
                                              "sBoxStemdoerExperience.present"
                                            )
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _vm.getTech(
                                experience.experiences[0].technologies
                              ).length > 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "d-flex flex-wrap ml-7",
                                      staticStyle: {
                                        "margin-left": "-1.2em",
                                        "row-gap": "10px",
                                      },
                                      attrs: {
                                        "data-cy":
                                          "stemdoer-experience-single-techs",
                                      },
                                    },
                                    _vm._l(
                                      _vm.getTech(
                                        experience.experiences[0].technologies
                                      ),
                                      function (tech, stIndex) {
                                        return _c(
                                          "div",
                                          { key: stIndex },
                                          [
                                            _c(
                                              "v-chip",
                                              {
                                                staticClass: "mr-1 chips",
                                                attrs: {
                                                  "data-cy":
                                                    "stemdoer-experience-single-tech-chip",
                                                  density: "compact",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(tech) +
                                                    "\n                "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "ml-7 mt-4" },
                                [
                                  _c("SReadMore", {
                                    staticStyle: { "margin-left": "-2px" },
                                    attrs: {
                                      "data-cy":
                                        "stemdoer-experience-single-description",
                                      text: experience.experiences[0]
                                        .description,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.stemdoerMutable.experience.length - 1 != exIndex
                        ? _c("hr", {
                            staticClass: "mt-4 mb-6",
                            staticStyle: {
                              "margin-left": "-1em",
                              width: "100%",
                            },
                            attrs: {
                              "data-cy": "stemdoer-experience-separator",
                            },
                          })
                        : _vm._e(),
                    ]
                  )
                }
              ),
              0
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }