import { defineComponent, computed, ref, watch } from 'vue';

export default defineComponent({
  props: {
    value: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    errorMessage: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const inputVal = ref(props.value || '');
    const inputValCount = computed(() => (props.value ? props.value.length : inputVal.value.length));
    const maxInputLength = 200;

    watch(
      () => props.value,
      val => {
        inputVal.value = val;
      }
    );

    return { inputVal, inputValCount, maxInputLength };
  }
});
